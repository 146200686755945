// hooks
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack5";



import { selectCheckout } from "services/store/checkoutSlice";
// Models
import {
  SaveClientPayloadProps,
  getProtocolPayloadProps,
} from "services/requests/models/payload";
import {
  PreventConsultResponseProps,
  SaveSaleResponseProps,
} from "services/requests/models/response";
import { CardValuesProps, MethodProps, StepsDataProps } from "./model";
// View
import View from "./view";
import { PATH_PAGE } from "routes/paths";
import { makeConclusion } from "services/store/conclusionSlice";
import { useDispatch } from "react-redux";
import Identification from "./components/identification";

const steps: string[] = [
  "Carrinho",
  "Consulta Prévia",
  "Identificação",
  "Pagamento",
];

const paymentOptions = [
  {
    ativo: true,
    metodo_id: 1,
    metodo_nome: "Boleto",
  },
  {
    ativo: true,
    metodo_id: 2,
    metodo_nome: "Cartão de Crédito",
  },
  {
    ativo: true,
    metodo_id: 1,
    metodo_nome: "Pix",
  },
];
const installmentsOptions = [
  {
    id: 1,
    label: "1x sem juros",
  },
  {
    id: 2,
    label: "2x sem juros",
  },
  {
    id: 3,
    label: "3x sem juros",
  },
  {
    id: 4,
    label: "4x sem juros",
  },
  {
    id: 5,
    label: "5x sem juros",
  },
  {
    id: 6,
    label: "6x sem juros",
  },
  {
    id: 7,
    label: "7x sem juros",
  },
  {
    id: 8,
    label: "8x sem juros",
  },
  {
    id: 9,
    label: "9x sem juros",
  },
  {
    id: 10,
    label: "10x sem juros",
  },
  {
    id: 11,
    label: "11x sem juros",
  },
  {
    id: 12,
    label: "12x sem juros",
  },
];

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export const purchase = (values : string, id : string) => {
  window.dataLayer.push({
    event: 'purchase',
    value: values,
    transactionID : id  
  });
};


const Checkout: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // View
  const checkout = useSelector(selectCheckout);
  const isCnpj = checkout.product === "e-CNPJ" || checkout.product === "e-PJ";
  const [currentStep, setCurrentStep] = useState("Carrinho");
  const isCart = currentStep === "Carrinho";
  const isConsult = currentStep === "Consulta Prévia";
  const isPayment = currentStep === "Pagamento";
  const isIdentification = currentStep === "Identificação";
  const isConclusion = currentStep === "Conclusão";
  const [isLoading, setIsLoading] = useState(false);
  const [stepsData, setStepsData] = useState<StepsDataProps>({
    preventConsult: {
      CNPJ: "",
      CPF: "",
      DataNascimento: "",
      cnh: false,
    },
    identification: {
      // identificação do titular
      cpf: "",
      name: "",
      cellphone: "",
      email: "",
      birthday: "",
      cnh: false,

      // localização do titular
      cep: "",
      public_place: "", // logradouro
      number: "",
      complement: "",
      neighborhood: "", // bairro
      city: "",
      uf: "",

      // identificação da empresa
      companyCnpj: "",
      companyName: "", // razão social
      fantasyName: "",
      companyCellphone: "",
      companyEmail: "",

      // localização empresa
      companyCep: "",
      companyPublic_place: "",
      companyNumber: "",
      companyComplement: "",
      companyNeighborhood: "",
      companyCity: "",
      companyUf: "",
    },
    // partnerAccountantCpf: "", // CPF do Contador parceiro, caso o cliente tenha sido indicado por alguma contabilidade.
    paymentMethods: {
      cardValues: {
        cardName: "",
        cardNumber: "",
        expirationDate: "",
        cvv: "",
        installments: {
          id: 0,
          label: "",
        },
      },
      method: {
        metodo_id: 0,
        metodo_nome: "",
        metodo_vezes: 0,
      },
    },
    protocol: "",
  });

  // Stepper
  useEffect(() => {
    switch (currentStep) {
      case "Carrinho":
        navigate("/checkout/carrinho")
        break;
      case "Consulta Prévia":
        navigate("/checkout/consulta-previa")
        break;
      case "Identificação":
        navigate("/checkout/identificacao")
        break;
      case "Pagamento":
        navigate("/checkout/pagamento")
        break;
      default:
        break;
    }
  }, [currentStep])
  

  // PreventConsult
  const [makeConsult, setMakeConsult] = useState(false);

  // Identification
  const [preventConsultResponse, setPreventConsultResponse] =
    useState<PreventConsultResponseProps>({
      CPF: "",
      DataNascimento: "",
      Nome: "",
      RazaoSocial: "",
      IsOK: true,
      cnh: false,
    });

  const [saveClientPayload, setSaveClientPayload] =
    useState<SaveClientPayloadProps>();

  // PaymentMethods

  const [makeSubmitPaymentMethod, setMakeSubmitPaymentMethod] = useState(false);

  var axios = require("axios");
  var qs = require("qs");

  // Preview
  const [previewData, setPreviewData] = useState<SaveSaleResponseProps>({
    HashLancamento: "",
    LinkFatura: "",
    Pedido: "",
    Status: 0,
  });

  // Requisições
  const saveClient = (formValues: any) => {
    console.log(stepsData)
    setStepsData({ ...stepsData, identification: formValues });
    const {
      companyCnpj,
      companyName,
      fantasyName,
      companyCellphone,
      companyEmail,
      companyCep,
      companyPublic_place,
      companyNumber,
      companyComplement,
      companyNeighborhood,
      companyCity,
      companyUf,

      cpf,
      name,
      cellphone,
      email,
      cep,
      public_place,
      number,
      complement,
      neighborhood,
      city,
      uf,
    } = formValues;

    const payload = isCnpj
      ? ({
          apiKey: "12567wfrdf47c2843ddb7a9e20d4ew2",
          cnpjcpf: companyCnpj
            .replace(".", "")
            .replace(".", "")
            .replace("/", "")
            .replace("-", ""),
          nomeRazaoSocial: companyName,
          nomeFantasia: fantasyName,
          email: companyEmail,
          cep: companyCep.replace("-", ""),
          logradouro: companyPublic_place,
          numero: companyNumber,
          complemento: companyComplement,
          bairro: companyNeighborhood,
          cidade: companyCity,
          uf: companyUf,
          ddd: companyCellphone.split(" ")[0],
          telefone: companyCellphone.replace("-", "").split(" ")[1],
          cnh: cnh,
        } as SaveClientPayloadProps)
      : ({
          apiKey: "12567wfrdf47c2843ddb7a9e20d4ew2",
          cnpjcpf: cpf.replace(".", "").replace(".", "").replace("-", ""),
          nomeRazaoSocial: name,
          nomeFantasia: fantasyName,
          email,
          cep: cep.replace("-", ""),
          logradouro: public_place,
          numero: number,
          complemento: complement,
          bairro: neighborhood,
          cidade: city,
          uf: uf,
          ddd: cellphone.split(" ")[0],
          telefone: cellphone.replace("-", "").split(" ")[1],
          cnh: cnh,
        } as SaveClientPayloadProps);

    setSaveClientPayload(payload);
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://apiar.safeweb.com.br/passwordapi/api/vendasportal/Client/",
      header: {
        "content-type": "application/json",
      },
      data: qs.stringify(payload),
    };

    setIsLoading(true);

    axios
      .request(config)
      .then((response: any) => {
        if (response.data.Status === 0) {
          setIsLoading(false);
          setCurrentStep("Pagamento");
          return;
        }
        enqueueSnackbar("Ocorreu um erro ao salvar o Cliente", {
          variant: "error",
        });
        console.log("saveClient", response.data);
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar("Houve um erro ao enviar o Formulário", {
          variant: "error",
        });
        setIsLoading(false);
      });
  };

  const preventConsult = (formValues: any) => {
    setStepsData({ ...stepsData, preventConsult: formValues, identification: { ...stepsData.identification, cnh: formValues.cnh } });
    const [day, month, year] = formValues.DataNascimento.split("/");

    const DataNascimento = `${year}-${month}-${day} `;
    const cnh = formValues.cnh;
    const CPF: string = formValues.CPF.replace(".", "")
      .replace(".", "")
      .replace("-", "");
    const CNPJ: string = formValues.CNPJ.replace(".", "")
      .replace(".", "")
      .replace(".", "")
      .replace("/", "")
      .replace("-", "");

    const payload = {
      CNPJ,
      CPF,
      DataNascimento,
      cnh,
      apiKey: "12567wfrdf47c2843ddb7a9e20d4ew2",
    };

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://apiar.safeweb.com.br/passwordapi/api/vendasportal/ConsultaPrevia/",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(payload),
    };

    setIsLoading(true);
    axios(config)
      .then((response: any) => {
        const data = response.data as PreventConsultResponseProps;
        if (data.IsOK) {
          enqueueSnackbar("Consulta realizada com Sucesso!", {
            variant: "success",
          });

          setPreventConsultResponse(data);
          setCurrentStep("Identificação");
          setIsLoading(false);
          return;
        }
        if (data.Erro?.includes("4")) {
          enqueueSnackbar(
            "Data de nascimento Incompatível com o CPF informado",
            {
              variant: "error",
            }
          );
          setIsLoading(false);

          return;
        }
        enqueueSnackbar(data.Erro, {
          variant: "error",
        });
        console.log("preventConsult", response.data);
        setIsLoading(false);
      })
      .catch(function (error: any) {
        console.log(error);
        setIsLoading(false);
      });
  };
 
  
  

  const saveSale = () => {
    const { paymentMethods } = stepsData;
    const { method } = paymentMethods;
    const payload = {
      apiKey: "12567wfrdf47c2843ddb7a9e20d4ew2",
      formaPagamento: method.metodo_id,
      documentoVendedor: "", // Contador Parceiro - Quem indicou a venda
      // idTabelaPreco: 1, // Opcional
      Observacao: "",
      cliente: saveClientPayload,
      produtos: [
        {
          hashProduto: checkout.hashProduto,
          quantidade: 1,
          valorUnitario: checkout.valorUnitario,
        },
      ],
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://apiar.safeweb.com.br/passwordapi/api/vendasportal/Sale/",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(payload),
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response: any) => {
        if (response.data.Status === 9) {
          response.data.Erros.forEach((erro: any) => {
            enqueueSnackbar(erro.ErrorDescription, {
              variant: "error",
            });
          });
          console.log("saveSale", response.data);
         
          return;
        }
        setPreviewData(response.data);
        getOrder(response.data.Pedido);
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getOrder = (pedido: string) => {
    let payload = {
      apiKey: "12567wfrdf47c2843ddb7a9e20d4ew2",
      order: pedido,
      document: isCnpj
        ? stepsData.identification.companyCnpj
        : stepsData.identification.cpf,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://apiar.safeweb.com.br/passwordapi/api/vendasportal/GetOrder/",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(payload),
    };

    axios
      .request(config)
      .then((response: any) => {
        if (response.data.Status === 9) {
          response.data.Erros.forEach((erro: any) => {
            enqueueSnackbar(erro.ErrorDescription, {
              variant: "error",
            });
          });
          console.log("getOrder", response.data);
          setIsLoading(false);
          return;
        }
        getProtocol(response.data.Produtos[0].HashVenda, response.data.Produtos[0].LinkFatura);
        
      })
      .catch((error: any) => {
        enqueueSnackbar("Ocorreu um erro ao obter a venda", {
          variant: "error",
        });
        setIsLoading(false);
        console.log(error);
      });
  };

  const cnh : any = stepsData.identification.cnh;

 console.log(cnh);

  const getProtocol = (HashVenda: string, link: string) => {
    const { identification } = stepsData;
    const {
      companyCnpj,
      companyName,
      companyCellphone,
      companyEmail,
      companyCep,
      companyPublic_place,
      companyNumber,
      companyComplement,
      companyNeighborhood,
      companyCity,
      companyUf,

      cpf,
      name,
      cellphone,
      email,
      cep,
      public_place,
      number,
      complement,
      neighborhood,
      city,
      uf,
    } = identification;
    const payload = isCnpj
      ? ({
          apiKey: "12567wfrdf47c2843ddb7a9e20d4ew2",
          swHashVenda: HashVenda,
          swCNPJ: companyCnpj,
          swIsPossuiCNH: stepsData.identification.cnh,
          swCPF: cpf,
          swDataNascimento: preventConsultResponse.DataNascimento,
          swNomeRazaoSocial: companyName,
          swDDD: companyCellphone.split(" ")[0],
          swTelefone: companyCellphone.replace("-", "").split(" ")[1],
          swEmail: companyEmail,
          swLogradouro: companyPublic_place,
          swNumero: companyNumber,
          swComplemento: companyComplement,
          swBairro: companyNeighborhood,
          swCidade: companyCity,
          swUF: companyUf,
          swCEP: companyCep,

          swNomeTitular: name,
          swTitularBairro: neighborhood,
          swTitularCidade: city,
          swTitularComplemento: complement,
          swTitularDDD: companyCellphone.split(" ")[0],
          swTitularTelefone: companyCellphone.replace("-", "").split(" ")[1],
          swTitularEmail: companyEmail,
          swTitularLogradouro: public_place,
          swTitularNumero: number,
          swTitularUF: uf,
          swTitularCEP: cep,
        } as getProtocolPayloadProps)
      : ({
        apiKey: "12567wfrdf47c2843ddb7a9e20d4ew2",
        swHashVenda: HashVenda,
        swCPF: cpf,
        swIsPossuiCNH: stepsData.identification.cnh,
        swDataNascimento: preventConsultResponse.DataNascimento,
        swNomeRazaoSocial: name,
        swDDD: cellphone.split(" ")[0],
        swTelefone: cellphone.replace("-", "").split(" ")[1],
        swEmail: email,
        swLogradouro: public_place,
        swNumero: number,
        swComplemento: complement,
        swBairro: neighborhood,
        swCidade: city,
        swUF: uf,
        swCEP: cep,
      } as unknown as getProtocolPayloadProps);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://apiar.safeweb.com.br/passwordapi/api/vendasportal/EmitirProtocolo/",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(payload),
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response: any) => {
        setStepsData({ ...stepsData, protocol: response.data.Protocolo });
        if (response.data.IsOK) {
          if (stepsData.paymentMethods.method.metodo_nome === "Boleto") {
            enqueueSnackbar(
              "Acabamos de te enviar um email com acesso ao Boleto!",
              { variant: "success" }
            );
          }
          dispatch(
            makeConclusion({
              cpf,
              name,
              protocol: response.data.Protocolo,
              invoiceLink: link,
              email,
              value: checkout.valorUnitario
            })
          );
         // sendMessage(response.data.Protocolo);
          navigate("/checkout/conclusao");
          return;
        }
        enqueueSnackbar("Ouve um erro ao emitir o Protocolo!", {
          variant: "error",
        });
        console.log("getProtocol", response.data);
        dispatch(
          makeConclusion({
            cpf,
            name,
            email,
            protocol: response.data.Protocolo,
            value: checkout.valorUnitario,
            invoiceLink: link,
          })
        );
        navigate("/checkout/conclusao");
      })
      .catch((error: any) => {
        //sendMessage("");
        console.log(error);
        setIsLoading(false);
      });
  };

  const sendMessage = (protocol: string) => {
    const { cellphone, companyCellphone } = stepsData.identification;

    const getNumber = () => {
      if (isCnpj) {
        return companyCellphone
          .replace(" ", "")
          .replace("-", "")
          .replace("9", "");
      }
      return cellphone.replace(" ", "").replace("-", "").replace("9", "");
    };

    const { product, model, validity } = checkout;

    let message = "";

    if (protocol) {
      message = `
        Olá *${stepsData.identification.name}*
        Obrigado pela preferência! 😁 
        Sua compra foi efetuada com sucesso.
        Seu protocolo é *${protocol}*.
        Certificado *${product} ${model} ${validity}*.
        Iremos prosseguir com seu agendamento por este canal.
        Em breve um de nossos atendentes 
        irá lhe atender.
      `;
    } else {
      message = `
      Olá *${stepsData.identification.name}*
      Obrigado pela preferência! 😁 
      Sua compra foi efetuada com sucesso.
      Certificado *${product} ${model} ${validity}*.
      Iremos prosseguir com seu agendamento por este canal.
      Em breve um de nossos atendentes 
      irá lhe atender.
    `;
    }

    let data = qs.stringify({
      text: message,
      number: getNumber(),
      serviceId: "741c568d-03dc-45b4-bf6c-818ee958e89e",
      userId: "c01200f0-6a3a-4aef-bc95-21e7ffd3092f",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://password.digisac.chat/api/v1/messages",
      headers: {
        Authorization: "Bearer db531f2d4e7c5a2b50f02a385948b3e88926a96c",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response: any) => {
        //
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  // End Requisições

  // General Functions
  const handleSubmitPaymentMethod = (values: {
    method: MethodProps;
    cardValues: CardValuesProps;
  }) => {
    if (!values.method.metodo_id) {
      enqueueSnackbar("Selecione um Método de pagamento \n para prosseguir.", {
        variant: "error",
      });
      return;
    }
    setStepsData({
      ...stepsData,
      paymentMethods: {
        method: values.method,
        cardValues: values.cardValues,
      },
    });
  };

  const handleNextStep = () => {
    console.log(currentStep)
    if (isConsult) {
      setMakeConsult(true);
      return;
    }
    if (isPayment && !previewData.LinkFatura) {
      setMakeSubmitPaymentMethod(true);
      return;
    }
    if (isConclusion) {
      navigate("/home");
    }
    steps.forEach((step, index) => {
      if (step === currentStep) {
        setCurrentStep(steps[index + 1]);
      }
    });
  };

  const handleBackStep = () => {
    steps.forEach((step, index) => {
      if (step === currentStep) {
        if (step === "Carrinho") {
          navigate("home");
        }
        setCurrentStep(steps[index - 1]);
      }
    });
  };

  useEffect(() => {
    const { metodo_nome, metodo_id } = stepsData.paymentMethods.method;
    if (metodo_nome) {
      if (metodo_id !== 0) {
        saveSale();
       
      }
    }
  }, [stepsData.paymentMethods.method.metodo_nome]);

  useEffect(() => {
    !checkout.product && navigate(PATH_PAGE.selectProduct);
  }, []);

  return (
    <View
      {...{
        // Root
        setCurrentStep,
        handleNextStep,
        handleBackStep,
        isCnpj,
        isCart,
        isConsult,
        isIdentification,
        isPayment,
        isConclusion,
        isLoading,
        stepsData,

        // Stepper
        steps,
        currentStep,

        // Cart
        checkout,

        // PreventConsult
        setMakeConsult,
        makeConsult,
        preventConsult,

        // Identification
        preventConsultResponse,
        saveClient,

        // PaymentMethods
        paymentOptions,
        installmentsOptions,
        setMakeSubmitPaymentMethod,
        makeSubmitPaymentMethod,
        handleSubmitPaymentMethod,
        previewData,
      }}
    />
  );
};

export default Checkout;
function getLink(LinkFatura: any) {
  throw new Error("Function not implemented.");
}

