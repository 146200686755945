import { Icon } from "@iconify/react";
import homeFill from "@iconify/icons-eva/home-fill";
import bookOpenFill from "@iconify/icons-eva/book-open-fill";
// routes
import { PATH_PAGE } from "../../routes/paths";

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: "Home",
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: "/",
  },
  {
    title: "e-CPF",
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: '/ecpf',
  },
  {
    title: "e-CNPJ",
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: "/ecnpj",
  },
  {
    title: "e-PJ",
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: "/epj",
  },
];

export default menuConfig;
