import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { IdentificationProps } from "../model";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  ConsultCepResponseProps,
  StatesResponseProps,
} from "services/requests/models/response";
import { useSnackbar } from "notistack5";

const Identification: React.FC<IdentificationProps> = ({
  preventConsultResponse,
  saveClient,
  isCnpj,
  handleBackStep,
  initialValues,
}) => {
  const [validationSchema, setValidationSchema] = useState({});
  const [citiesOptions, setCitiesOptions] = useState<string[]>([]);
  const [citiesCompanyOptions, setCitiesCompanyOptions] = useState<string[]>(
    []
  );
  const [states, setStates] = useState<StatesResponseProps[]>([]);
  const statesOptions = states.map((el) => el.uf);
  const rootSchema = Yup.object().shape({
    ...validationSchema,
  });
  const { enqueueSnackbar } = useSnackbar();

  const cpfSchema = {
    // identificação do titular

    name: Yup.string().required("O Nome é Obrigatório."),
    cpf: Yup.string()
      .required("O CPF é Obrigatório.")
      .length(11, "O CPF deve ter 11 números."),
    birthday: Yup.string()
      .required("A Data de Nascimento é Obrigatória.")
      .length(10, "Data incorreta."),

    // localização do titular
    cep: Yup.string()
      .required("O CEP é Obrigatório.")
      .length(9, "O CEP deve ter 8 números.")
      .nullable(),
    public_place: Yup.string().required("O Logradouro é Obrigatório."),
    neighborhood: Yup.string().required("O Bairro é Obrigatório."),
    number: Yup.string().required("O Número é Obrigatório."),
    complement: Yup.string(),
    city: Yup.string().required("A Cidade é Obrigatória."),
    uf: Yup.string().nullable().required("O Estado é Obrigatório."),
  };

  const cnpjSchema = {
    // identificação da Empresa
    companyName: Yup.string().required("A Razão Social é Obrigatória."),
    fantasyName: Yup.string(),
    companyCnpj: Yup.string()
      .required("O CNPJ é Obrigatório.")
      .length(14, "O CPF deve ter 14 números."),
    companyEmail: Yup.string()
      .required("O E-mail é Obrigatório.")
      .email("Insira um E-mail válido"),
    companyCellphone: Yup.string()
      .required("O Número de Celular do titular é Obrigatório.")
      .length(13, "O Celular deve ter 11 números."),

    // localização da Empresa
    companyCep: Yup.string()
      .required("O CEP é Obrigatório.")
      .length(9, "O CEP deve ter 8 números."),
    companyPublic_place: Yup.string().required("O Logradouro é Obrigatório."),
    companyNeighborhood: Yup.string().required("O Bairro é Obrigatório."),
    companyNumber: Yup.string().required("O Número é Obrigatório."),
    companyComplement: Yup.string(),
    companyCity: Yup.string().required("A Cidade é Obrigatória."),
    companyUf: Yup.string().nullable().required("O Estado é Obrigatório."),
  };

  const formik = useFormik({
    validationSchema: rootSchema,
    initialValues: initialValues,
    onSubmit: async (values) => saveClient(values),
  });

  const { getFieldProps, setFieldValue, handleSubmit, errors, touched } =
    formik;

  const getStates = () => {
    const axios = require("axios");
    const qs = require("qs");
    let payload = qs.stringify({
      apiKey: "12567wfrdf47c2843ddb7a9e20d4ew2",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://apiar.safeweb.com.br/passwordapi/api/vendasportal/State/",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response: any) => {
        setStates(
          response.data.map((el: StatesResponseProps) => {
            const stateObj = {
              uf: el.uf,
              ufName: el.ufName,
              ufIBGE: el.ufIBGE,
            };
            return stateObj;
          })
        );
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar("Erro ao buscar os Estados", { variant: "error" });
      });
  };

  const setCities = (currentStateCode: string, field?: string) => {
    if (!currentStateCode) return;
    const axios = require("axios");
    const qs = require("qs");
    let data = qs.stringify({
      apiKey: "12567wfrdf47c2843ddb7a9e20d4ew2",
      CodigoIbgeUF: currentStateCode,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://apiar.safeweb.com.br/passwordapi/api/vendasportal/MunicipiosLocaisAtendimento/",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios
      .request(config)
      .then(({ data }: any) => {
        if (field === "titular") {
          setCitiesOptions(data.map((el: any) => el.NomeMunicipio));
          return;
        }
        setCitiesCompanyOptions(data.map((el: any) => el.NomeMunicipio));
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStates();
    const { CPF, DataNascimento, Nome, RazaoSocial, CNPJ } =
      preventConsultResponse;

    setFieldValue("birthday", dayjs(DataNascimento).format("DD/MM/YYYY"));
    setFieldValue("cpf", CPF);
    setFieldValue("name", Nome);

    if (isCnpj) {
      setFieldValue("companyName", RazaoSocial);
      setFieldValue("companyCnpj", CNPJ);
    }

    if (isCnpj) {
      setValidationSchema({ ...cnpjSchema, ...cpfSchema });

      setFieldValue("companyName", RazaoSocial);
      return;
    }
    Object.assign(cpfSchema, {
      cellphone: Yup.string()
        .required("O Número de Celular do titular é Obrigatório.")
        .min(12, "O Celular deve ter 11 números"),
      email: Yup.string()
        .required("O E-mail é Obrigatório.")
        .email("Insira um E-mail válido"),
    });
    setValidationSchema({ ...cpfSchema });
  }, []);

  const consultCep = (cep: string, field?: string) => {
    cep.replace("-", "");
    const axios = require("axios");

    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://viacep.com.br/ws/${cep}/json/`,
    };

    axios(config)
      .then((response: any) => {
        if (response.data.erro) {
          enqueueSnackbar("Erro ao buscar as informações do endereço.", {
            variant: "error",
          });
          if (field === "titular") formik.setFieldError("cep", "CEP inválido");
          formik.setFieldTouched("cep", true);
          formik.setFieldError("companyCep", "CEP inválido");
          return;
        }
        const { logradouro, complemento, bairro, localidade, uf } =
          response.data as ConsultCepResponseProps;
        if (field === "titular") {
          setFieldValue("public_place", logradouro);
          setFieldValue("complement", complemento);
          setFieldValue("neighborhood", bairro);
          setFieldValue("uf", uf);
          setFieldValue("city", localidade);
          return;
        }
        setFieldValue("companyPublic_place", logradouro);
        setFieldValue("companyComplement", complemento);
        setFieldValue("companyNeighborhood", bairro);
        setFieldValue("companyUf", uf);
        setFieldValue("companyCity", localidade);
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar("Houve um erro ao consultar o cep.", {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    const cep = getFieldProps("cep").value;
    const field = "titular";
    if (cep?.length >= 9) {
      consultCep(cep.replace("-", ""), field);
    }
  }, [getFieldProps("cep").value]);

  useEffect(() => {
    const cep = getFieldProps("companyCep").value;
    if (cep?.length >= 9) {
      consultCep(cep);
    }
  }, [getFieldProps("companyCep").value]);

  useEffect(() => {
    const currentState = getFieldProps("uf").value;
    const currentStateCode = states
      .filter((state) => state.uf === currentState)
      .map((state) => state.ufIBGE)[0];
    setFieldValue("city", "");
    setCities(currentStateCode, "titular");
  }, [getFieldProps("uf").value]);

  useEffect(() => {
    const currentState = getFieldProps("companyUf").value;
    const currentStateCode = states
      .filter((state) => state.uf === currentState)
      .map((state) => state.ufIBGE)[0];
    setFieldValue("companyCity", "");
    setCities(currentStateCode);
  }, [getFieldProps("companyUf").value]);

  return (
    <FormikProvider value={formik}>
      <Form
        onSubmit={handleSubmit}
        autoComplete="off"
        noValidate
        style={{ width: "100%" }}
      >
        <Grid id="general" container direction="column" spacing={2}>
          {/* titular  */}

          <Grid
            id="titular"
            item
            container
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
          >
            <Grid item xs={12} alignSelf="start">
              <Typography variant="h4">Titular</Typography>
            </Grid>
            <Grid item xs={12} alignSelf="start">
              <Typography variant="h6">Identificação</Typography>
            </Grid>
            <Grid item xs={3}>
              <InputMask
                {...getFieldProps("cpf")}
                disabled
                mask="999.999.999-99"
                maskChar=""
                error={Boolean(touched.cpf && errors.cpf)}
                helperText={touched.cpf && errors.cpf}
              >
                {(inputprops: any) => (
                  <TextField disabled fullWidth label="CPF" {...inputprops} />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Nome"
                disabled
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={3}>
              <InputMask
                mask="99/99/9999"
                maskChar=""
                disabled
                error={Boolean(touched.birthday && errors.birthday)}
                helperText={touched.birthday && errors.birthday}
                {...getFieldProps("birthday")}
              >
                {(props: any) => (
                  <TextField
                    {...props}
                    disabled
                    fullWidth
                    label="Data de Nascimento"
                  />
                )}
              </InputMask>
            </Grid>
            {!isCnpj && (
              <>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    label="E-mail"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    {...getFieldProps("email")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputMask
                    mask="99 99999-9999"
                    maskChar=""
                    error={Boolean(touched.cellphone && errors.cellphone)}
                    helperText={touched.cellphone && errors.cellphone}
                    {...getFieldProps("cellphone")}
                  >
                    {(props: any) => (
                      <TextField {...props} fullWidth label="Celular" />
                    )}
                  </InputMask>
                </Grid>
              </>
            )}
           <Grid item xs={3}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native" >
              POSSUI CNH?
            </InputLabel>
            <Checkbox checked={initialValues.cnh} size="small" />Sim
          </Grid> 

            <Grid item xs={12}>
              <Typography variant="h6">Endereço</Typography>A
            </Grid>
            <Grid item xs={3}>
              <InputMask
                mask="99999-999"
                maskChar=""
                {...getFieldProps("cep")}
                error={Boolean(touched.cep && errors.cep)}
                helperText={touched.cep && errors.cep}
              >
                {(props: any) => <TextField {...props} fullWidth label="CEP" />}
              </InputMask>
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                {...getFieldProps("uf")}
                disabled
                onChange={(event, value) => setFieldValue("uf", value)}
                options={statesOptions}
                renderInput={(inputProps: any) => (
                  <TextField
                    fullWidth
                    label="Estado"
                    {...inputProps}
                    error={Boolean(touched.uf && errors.uf)}
                    helperText={touched.uf && errors.uf}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                {...getFieldProps("city")}
                onChange={(event, value) => setFieldValue("city", value)}
                options={citiesOptions}
                disabled
                renderInput={(inputProps: any) => (
                  <TextField
                    fullWidth
                    label="Cidade"
                    {...inputProps}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Número"
                {...getFieldProps("number")}
                error={Boolean(touched.number && errors.number)}
                helperText={touched.number && errors.number}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Bairro"
                {...getFieldProps("neighborhood")}
                error={Boolean(touched.neighborhood && errors.neighborhood)}
                helperText={touched.neighborhood && errors.neighborhood}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Logradouro"
                {...getFieldProps("public_place")}
                error={Boolean(touched.public_place && errors.public_place)}
                helperText={touched.public_place && errors.public_place}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Complemento (opcional)"
                {...getFieldProps("complement")}
                error={Boolean(touched.complement && errors.complement)}
                helperText={touched.complement && errors.complement}
              />
            </Grid>
          </Grid>
          {/* end titular */}

          {isCnpj && (
            <Grid item sx={{ width: "100%", mt: 2 }}>
              <Divider />
            </Grid>
          )}

          {/* empresa  */}
          {isCnpj && (
            <Grid id="empresa-info" item container direction="row" spacing={2}>
              <Grid item xs={12} alignSelf="start">
                <Typography variant="h4">Empresa</Typography>
              </Grid>
              <Grid item xs={12} alignSelf="start">
                <Typography variant="h6">Identificação</Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Razão Social"
                  {...getFieldProps("companyName")}
                  disabled
                  error={Boolean(touched.companyName && errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                />
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="99.999.999/9999-99"
                  maskChar=""
                  disabled
                  helperText={touched.companyCnpj && errors.companyCnpj}
                  error={Boolean(touched.companyCnpj && errors.companyCnpj)}
                  {...getFieldProps("companyCnpj")}
                >
                  {(inputprops: any) => (
                    <TextField
                      disabled
                      fullWidth
                      label="CNPJ"
                      {...inputprops}
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="E-mail"
                  {...getFieldProps("companyEmail")}
                  error={Boolean(touched.companyEmail && errors.companyEmail)}
                  helperText={touched.companyEmail && errors.companyEmail}
                />
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  mask="99 99999-9999"
                  maskChar=""
                  {...getFieldProps("companyCellphone")}
                  error={Boolean(
                    touched.companyCellphone && errors.companyCellphone
                  )}
                  helperText={
                    touched.companyCellphone && errors.companyCellphone
                  }
                >
                  {(inputProps: any) => (
                    <TextField {...inputProps} fullWidth label="Celular" />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Endereço</Typography>
              </Grid>
              <Grid item xs={3}>
                <InputMask
                  mask="99999-999"
                  maskChar=""
                  {...getFieldProps("companyCep")}
                  error={Boolean(touched.companyCep && errors.companyCep)}
                  helperText={touched.companyCep && errors.companyCep}
                >
                  {(props: any) => (
                    <TextField {...props} fullWidth label="CEP" />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  {...getFieldProps("companyUf")}
                  onChange={(event, value) => setFieldValue("companyUf", value)}
                  disabled
                  options={statesOptions}
                  renderInput={(inputProps: any) => (
                    <TextField
                      fullWidth
                      label="Estado"
                      error={Boolean(touched.companyUf && errors.companyUf)}
                      helperText={touched.companyUf && errors.companyUf}
                      {...inputProps}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  {...getFieldProps("companyCity")}
                  onChange={(event, value) =>
                    setFieldValue("companyCity", value)
                  }
                  disabled
                  options={citiesCompanyOptions}
                  renderInput={(inputProps: any) => (
                    <TextField
                      fullWidth
                      label="Cidade"
                      {...inputProps}
                      error={Boolean(touched.companyCity && errors.companyCity)}
                      helperText={touched.companyCity && errors.companyCity}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Número"
                  {...getFieldProps("companyNumber")}
                  error={Boolean(touched.companyNumber && errors.companyNumber)}
                  helperText={touched.companyNumber && errors.companyNumber}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Bairro"
                  {...getFieldProps("companyNeighborhood")}
                  error={Boolean(
                    touched.companyNeighborhood && errors.companyNeighborhood
                  )}
                  helperText={
                    touched.companyNeighborhood && errors.companyNeighborhood
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Logradouro"
                  {...getFieldProps("companyPublic_place")}
                  error={Boolean(
                    touched.companyPublic_place && errors.companyPublic_place
                  )}
                  helperText={
                    touched.companyPublic_place && errors.companyPublic_place
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Complemento (opcional)"
                  {...getFieldProps("companyComplement")}
                  error={Boolean(
                    touched.companyComplement && errors.companyComplement
                  )}
                  helperText={
                    touched.companyComplement && errors.companyComplement
                  }
                />
              </Grid>
            </Grid>
          )}

          {/* end empresa */}

          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={6} md={3}>
              <Button
                size="large"
                variant="outlined"
                fullWidth
                onClick={handleBackStep}
              >
                voltar
              </Button>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button type="submit" size="large" variant="contained" fullWidth>
                Prosseguir
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default Identification;
