import Slider from "react-slick";
import { useRef } from "react";
import { Icon } from "@iconify/react";
// material
import { useTheme, styled } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
// utils
import { CarouselControlsArrowsBasic2 } from "components/carousel";
import { Card } from "@mui/material";

// ----------------------------------------------------------------------

const MOCK_CAROUSELS = [
  {
    id: 0,
    title: "Elaborar Declarações de Imposto de Renda",
    image: "fluent-emoji:lion",
    description: "asdZXVZf",
  },
  {
    id: 1,
    title: "Acessar ás informações do e-CAC",
    image: "icon-park:computer",
    description: "asdf",
  },
  {
    id: 2,
    title: "Assinar documentos, contratos, recibos e declarações",
    image: "fa6-solid:file-signature",
    description: "asdf",
  },
  {
    id: 3,
    title: "Acessar a diversos serviços e portais eletrônicos",
    image: "icon-park:click",
    description: "asdf",
  },
  {
    id: 4,
    title: "Reconhecer firma",
    image: "mdi:file-document-check",
    description: "asdf",
  },
  {
    id: 5,
    title: "Acessar serviços e portais oferecidos pelo Governo Federal",
    image: "mdi:verified-user",
    description: "asdf",
  },
  {
    id: 6,
    title: "Solicitar CNH Digital on-line",
    image: "fa:drivers-license",
    description: "asdf",
  },
];

const RootStyle = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  "&:before, &:after": {
    top: 0,
    left: 0,
    zIndex: 8,
    width: 48,
    content: "''",
    height: "100%",
    display: "none",
    position: "absolute",
    [theme.breakpoints.up(480)]: {
      display: "block",
    },
  },
  "&:after": {
    right: 0,
    left: "auto",
    transform: "scaleX(-1)",
  },
}));

// ----------------------------------------------------------------------

type CarouselItemProps = {
  title: string;
  description: string;
  image: string;
};

function CarouselItem({ item }: { item: CarouselItemProps }) {
  const { image, title } = item;

  return (
    <Card
      sx={{
        padding: 2,
        margin: 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Icon icon={image} width={40} color="orange" />
      <Typography variant="body2" color="text.secondary">
        {title}
      </Typography>
    </Card>
  );
}

export default function Carousel() {
  const carouselRef = useRef<Slider | null>(null);
  const theme = useTheme();

  const settings = {
    slidesToShow: 3,
    centerMode: true,
    centerPadding: "60px",
    rtl: Boolean(theme.direction === "rtl"),
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, centerPadding: "0" },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <RootStyle>
      <Slider ref={carouselRef} {...settings}>
        {MOCK_CAROUSELS.map((item) => (
          <CarouselItem key={item.id} item={item} />
        ))}
      </Slider>
      <CarouselControlsArrowsBasic2
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </RootStyle>
  );
}
