import { useRef } from "react";
import Slider from "react-slick";
import { Icon } from "@iconify/react";
import twitterFill from "@iconify/icons-eva/twitter-fill";
import linkedinFill from "@iconify/icons-eva/linkedin-fill";
import facebookFill from "@iconify/icons-eva/facebook-fill";
import roundArrowRightAlt from "@iconify/icons-ic/round-arrow-right-alt";
import instagramFilled from "@iconify/icons-ant-design/instagram-filled";
// material
import { useTheme } from "@material-ui/core/styles";
import {
  Box,
  Card,
  Button,
  Container,
  Typography,
  IconButton,
} from "@material-ui/core";
// utils
// import mockData from "../../../utils/mock-data";
//
import {
  varFadeIn,
  varFadeInUp,
  MotionInView,
  varFadeInDown,
} from "components/animate";
import { CarouselControlsArrowsBasic2 } from "components/carousel";
import CarouselCenterMode from "./CarouselCenterMode";

// ----------------------------------------------------------------------

// const MOCK_MEMBERS = [...Array(5)].map((_, index) => ({
//   id: mockData.id(index),
//   name: mockData.name.fullName(index),
//   role: mockData.role(index),
//   avatar: mockData.image.avatar(index),
// }));

// ----------------------------------------------------------------------

type MemberCardProps = {
  member: {
    id: string;
    name: string;
    role: string | undefined;
    avatar: string;
  };
};

function MemberCard({ member }: MemberCardProps) {
  const { name, role, avatar } = member;
  return (
    <Card key={name} sx={{ p: 1, mx: 1.5 }}>
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 0.5 }}>
        {name}
      </Typography>
      <Typography variant="body2" sx={{ mb: 2, color: "text.secondary" }}>
        {role}
      </Typography>
      <Box
        component="img"
        src={avatar}
        sx={{ width: "100%", borderRadius: 1.5 }}
      />
      <Box sx={{ mt: 2, mb: 1 }}>
        {[facebookFill, instagramFilled, linkedinFill, twitterFill].map(
          (social, index) => (
            <IconButton key={index}>
              <Icon icon={social} width={20} height={20} />
            </IconButton>
          )
        )}
      </Box>
    </Card>
  );
}

interface CarouselCenterModeProps {
  id: number;
  item: {
    title: string;
    description: string;
    icon: string;
  };
}

export default function AboutTeam({
  carouselList,
  title,
  subtitle,
}: {
  carouselList: CarouselCenterModeProps[];
  title: string;
  subtitle: string;
}) {
  const carouselRef = useRef<Slider>(null);
  const theme = useTheme();

  const settings = {
    slidesToShow: 4,
    centerMode: true,
    centerPadding: "0 80px",
    rtl: Boolean(theme.direction === "rtl"),
    responsive: [
      {
        breakpoint: 1279,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 959,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <Container maxWidth="lg" sx={{ pb: 10, textAlign: "center" }}>
      <MotionInView variants={varFadeInDown}>
        <Typography
          component="p"
          variant="overline"
          sx={{ mb: 2, color: "text.secondary" }}
        >
          {subtitle}
        </Typography>
      </MotionInView>

      <MotionInView variants={varFadeInUp}>
        <Typography variant="h2" sx={{ mb: 3 }}>
          {title}
        </Typography>
      </MotionInView>

      <MotionInView variants={varFadeInUp}>
        <Typography
          sx={{
            mb: 10,
            mx: "auto",
            maxWidth: 630,
            color: (theme) =>
              theme.palette.mode === "light"
                ? "text.secondary"
                : "common.white",
          }}
        >
          {/* Minimal will provide you support if you have any problems, our support
          team will reply within a day and we also have detailed documentation. */}
        </Typography>
      </MotionInView>

      <Box sx={{ position: "relative" }}>
        {/* <Slider ref={carouselRef} {...settings}>
          {MOCK_MEMBERS.map((member) => (
            <MotionInView key={member.id} variants={varFadeIn}>
              <MemberCard member={member} />
            </MotionInView>
          ))}
        </Slider> */}
        <CarouselCenterMode {...{ carousel: carouselList }} />
        {/* <CarouselControlsArrowsBasic2
          onNext={handleNext}
          onPrevious={handlePrevious}
          sx={{ transform: "translateY(-64px)" }}
        /> */}
      </Box>
      {/* <Button
        variant="outlined"
        color="inherit"
        size="large"
        endIcon={<Icon icon={roundArrowRightAlt} width={24} height={24} />}
        sx={{ mx: "auto" }}
      >
        View all team members
      </Button> */}
    </Container>
  );
}
