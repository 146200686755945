import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import { fCurrency } from "utils/formatNumber";
import { ProductListProps } from "../model";

const ProductList: React.FC<ProductListProps> = ({ handleCheckout }) => {
  const mostPurchasedProducts = [
    // {
    //   product: "e-CPF",
    //   model: "A3",
    //   media: "SafeID",
    //   validity: "4 Meses",
    //   emission: "Videoconferência",
    //   price: "75",
    //   img: "static/mock-images/products/eCpf.png",
    //   name: "e-CPF A3 SafeId 4 meses",
    // },
    {
      product: "e-CPF",
      model: "A1",
      media: "Arquivo",
      validity: "1 Ano",
      emission: "Videoconferência",
      price: "120",
      img: "static/mock-images/products/eCpf.png",
      name: "e-CPF A1 1 Ano",
    },
    {
      product: "e-CPF",
      model: "A3",
      media: "Sem mídia",
      validity: "3 Anos",
      emission: "Videoconferência",
      price: "200",
      img: "static/mock-images/products/eCpf.png",
      name: "e-CPF A3 3 Anos",
    },
    {
      product: "e-CNPJ",
      model: "A1",
      media: "Arquivo",
      validity: "1 Ano",
      emission: "Videoconferência",
      price: "180",
      img: "static/mock-images/products/eCnpj.png",
      name: "e-CNPJ A1 1 Ano",
    },
    {
      product: "e-PJ",
      model: "A1",
      media: "Arquivo",
      validity: "1 Ano",
      emission: "Videoconferência",
      price: "200",
      img: "static/mock-images/products/ePj.jpg",
      name: "e-PJ A1 1 Ano",
    },
  ];

  return (
    <>
      <Typography
        id="product_list_title"
        textAlign="center"
        variant="h4"
        textTransform="uppercase"
        color="text.primary"
        sx={{ mt: 5 }}
      >
        Compre ou renove seu Certificado Digital
      </Typography>
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        sx={{ mt: 2 }}
      >
        {mostPurchasedProducts.map((item, index) => {
          return (
            <ProductItem key={index} {...{ item, index, handleCheckout }} />
          );
        })}
       
      </Grid>
    </>
  );
};

const ProductItem = ({
  item,
  index,
  handleCheckout,
}: {
  item: any;
  index: number;
  handleCheckout: (product: {
    product: string;
    model: string;
    media: string;
    validity: string;
    emission: string;
  }) => void;
}) => {
  const { product, model, media, validity, emission } = item;

  return (
    <Grid item xs={12} sm={4} md={3}>
      <Card key={index}>
        <Stack spacing={1} sx={{ padding: 2 }}>
          <Box
            sx={{
              borderRadius: "10px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="imagem do produto"
              style={{ borderRadius: "10px" }}
              src={item.img}
            />
          </Box>
          <Typography fontSize="2rem" variant="subtitle2" color="primary.main">
            {item.product}
          </Typography>
          <Stack>
            <Typography fontSize="0.875rem" variant="subtitle2">
              Tipo - {item.model}{" "}
              {item.model.includes("A1") && "(no computador)"}
            </Typography>
            <Typography fontSize="0.875rem" variant="subtitle2">
              Mídia -{" "}
              {item.media === "SafeID" ? item.media + " (Nuvem)" : item.media}
            </Typography>
            <Typography fontSize="0.875rem" variant="subtitle2">
              Validade - {item.validity}
            </Typography>
            <Typography fontSize="0.875rem" variant="subtitle2">
              Emissão - {item.emission}
            </Typography>
          </Stack>
          <Typography
            fontSize="1rem"
            fontWeight={700}
            align="right"
            variant="subtitle2"
          >
            {fCurrency(item.price)}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              handleCheckout({
                product,
                model,
                media,
                validity,
                emission,
              });
            }}
          >
            Comprar
          </Button>
        </Stack>
      </Card>
    </Grid>
  );
};

export default ProductList;
