import { styled, Card } from "@mui/material";
import Page from "components/Page";

export const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(11),
  },
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

export const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

export const StepIconComponent = styled("div")(() => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: "currentColor",
}));
