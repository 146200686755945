import { useState } from "react";
import { makeCheckout } from "services/store/checkoutSlice";
import { useDispatch } from "react-redux";
import { FormValuesProps } from "screens/home/model";
import { useNavigate } from "react-router";
import { PATH_PAGE } from "./../../routes/paths";
import View from "./view";


const a1MediaOption = ["Arquivo"];
const a3MediaOptions = [
  "Token",
  "Cartão",
  "Cartão + Leitora",
  "SafeID",
  "Sem Mídia",
];

const defaultValidities = ["1 Ano", "2 Anos", "3 Anos"];
const safeIdValidities = ["4 Meses", "1 Ano"];
const steps = ["Carrinho", "Pagamento"];

const Home: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheckout = (values: FormValuesProps) => {
    const axios = require("axios");
    const qs = require("qs");

    setIsLoading(true);
    const payload = {
      apiKey: "12567wfrdf47c2843ddb7a9e20d4ew2",
      document: "",
      type: values.product === "e-CPF" ? 1 : values.product === "e-PJ" ? 8 : 2,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://apiar.safeweb.com.br/passwordapi/api/vendasportal/PriceTable/",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(payload),
    };

    axios
      .request(config)
      .then(({ data }: any) => {
        getSelectedProduct(data.certificates, values);
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getSelectedProduct = (
    certificates: any[],
    formData: FormValuesProps
  ) => {
    const certificatesList: any[] = [];
    certificates.forEach((certificate: any) => {
      certificate.products.forEach((product: any) => {
        certificatesList.push(product);
      });
    });
    const product = certificatesList
      .map((certificate) => {
        const {
          id,
          CertName,
          TipoCertificado,
          TipoMidia,
          duration,
          TipoEmissao,
          sTipoEmissao,
          priceDecimal,
        } = certificate;
        return {
          hashProduto: id,
          productName: CertName,
          model: TipoCertificado,
          media: TipoMidia,
          validity: duration,
          emissionId: TipoEmissao,
          emissionString: sTipoEmissao,
          valorUnitario: priceDecimal,
        };
      })
      .filter((certificate) => {
        // api
        const media = certificate.media.toLowerCase();
        const productName = certificate.productName.toLowerCase();
        const emissionString = certificate.emissionString.toLowerCase();

        // form
        const formModel = formData.model.toLowerCase();
        const formMedia = formData.media.toLowerCase();
        const formValidity = formData.validity.toLowerCase();
        const formEmission = formData.emission.toLowerCase();
        const formProduct = formData.product.toLowerCase();

        const isA1 = productName.includes("a1") && formModel === "a1";

        const isCurrentMedia = () => {
          if (media === "nuvem" && formMedia === "safeid") return true;
          if (
            media === formMedia &&
            productName.includes("não incluso") === false
          )
            return true;
          if (productName.includes("não incluso") && formMedia === "sem mídia")
            return true;
          return false;
        };

        const isCurrentValidity = productName.includes(formValidity);

        if (emissionString === formEmission) {
          if (isA1) return true;
          if (productName.includes(formProduct)) {
            if (isCurrentMedia()) {
              if (isCurrentValidity) {
                return true;
              }
            }
          }
        }

        return false;
      })
      .map((certificate) => {
        const { hashProduto, valorUnitario } = certificate;
        return {
          ...formData,
          hashProduto,
          valorUnitario,
        };
      })[0];

    dispatch(makeCheckout(product));
    navigate(`${PATH_PAGE.checkout}/carrinho`);
  };

  return (
    <View
      {...{
        // root
        a1MediaOption,
        a3MediaOptions,
        defaultValidities,
        safeIdValidities,
        handleCheckout,
        isLoading,

        // product

        // stepper
        steps,
      }}
    />
  );
};

export default Home;
