import { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
  Box,
  Container,
} from "@mui/material";
import * as Yup from "yup";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import { FormProps } from "../model";

const Form: React.FC<FormProps> = ({
  a1MediaOption,
  a3MediaOptions,
  defaultValidities,
  safeIdValidities,
  handleCheckout,
  isLoading,
}) => {

  const validateSchema = Yup.object().shape({
    product: Yup.string()
      .nullable()
      .required("O tipo do produto é Obrigatório."),
    model: Yup.string()
      .nullable()
      .required("O modelo do certificado é Obrigatório."),
    media: Yup.string().nullable().required("O tipo da Mídia é Obrigatório."),
    validity: Yup.string()
      .nullable()
      .required("O tempo de Validade é Obrigatório."),
    emission: Yup.string()
      .nullable()
      .required("O tipo de emissão é Obrigatório."),
  });

  const formik = useFormik({
    validationSchema: validateSchema,
    initialValues: {
      product: "",
      model: "",
      media: "",
      validity: "",
      emission: "",
    },
    onSubmit: (values) => {
      handleCheckout(values);
    },
  });

  const { getFieldProps, setFieldValue, errors, touched } = formik;

  const isCnpj: boolean = getFieldProps("product").value?.includes("PJ");
  const isA1: boolean = getFieldProps("model").value === "A1";
  const isSafeId: boolean = getFieldProps("media").value === "SafeID";
  const isPhysicalMedia: boolean = !(
    getFieldProps("media").value === "Arquivo" ||
    getFieldProps("media").value === "Sem Mídia" ||
    getFieldProps("media").value === "SafeID"
  );

  const emissionOptions = () => {
    const product = getFieldProps("product").value;
    const media = getFieldProps("media").value;
    const validity = getFieldProps("validity").value;
    const emission = getFieldProps("emission").value;

    if (product === "e-PJ") {
      if (!media) {
        return ["Presencial", "On-line"];
      }
      if (!validity) {
        if (isPhysicalMedia) {
          if (emission === "On-line") setFieldValue("emission", "");
          return ["Presencial"];
        }
        return ["Presencial", "On-line"];
      }
      if (
        (media === "Arquivo" || media === "Sem Mídia") &&
        validity === "1 Ano"
      ) {
        return ["Presencial", "On-line"];
      }
      if (emission === "On-line") setFieldValue("emission", "");
      return ["Presencial"];
    }
    if (!media) {
      return ["Videoconferência", "Presencial", "Renovação", "On-line"];
    } else {
      if (media !== "Arquivo" && media !== "Sem Mídia" && media !== "SafeID") {
        return ["Presencial"];
      }
      if (product === "e-CNPJ") {
        return ["Videoconferência", "Presencial", "Renovação", "On-line"];
      }
      return ["Videoconferência", "Presencial", "Renovação"];
    }
  };

  useEffect(() => {
    if (isA1) {
      setFieldValue("media", "Arquivo");
      setFieldValue("validity", "1 Ano");
      return;
    }
    if (getFieldProps("media").value === "Arquivo") {
      setFieldValue("media", "");
    }
  }, [isA1]);

  useEffect(() => {
    if (isSafeId && getFieldProps("validity").value === "4 Meses") {
      setFieldValue("validity", "");
      return;
    }
    if (getFieldProps("validity").value === "4 Meses") {
      setFieldValue("validity", "");
    }
  }, [isSafeId]);

  useEffect(() => {
    if (!isCnpj && getFieldProps("emission").value === "On-line") {
      setFieldValue("emission", "");
    }
  }, [isCnpj]);

  useEffect(() => {
    if (isPhysicalMedia && getFieldProps("emission").value !== "Presencial") {
      setFieldValue("emission", "");
    }
  }, [isPhysicalMedia]);

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={{ padding: 3, mb: 3 }}>
          <FormikProvider value={formik}>
            <FormikForm style={{ height: "100%" }}>
              <Grid
                id="first"
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={{ xs: 4, sm: 7 }}
              >
                <Grid item xs={12}>
                  <Typography
                    id="form_title"
                    textAlign="left"
                    variant="h4"
                    textTransform="uppercase"
                    color="text.primary"
                  >
                    Monte o certificado ideal para você!
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  spacing={{ xs: 2, sm: 3 }}
                >
                  <Grid item xs={12} sm={4} md={2}>
                    <Autocomplete
                      size="medium"
                      {...getFieldProps("product")}
                      onChange={(event, value) =>
                        setFieldValue("product", value)
                      }
                      options={["e-CPF", "e-CNPJ", "e-PJ"]}
                      getOptionLabel={(option) => option}
                      renderInput={(props) => (
                        <TextField
                          label="Produto"
                          {...props}
                          fullWidth
                          aria-disabled
                          error={Boolean(touched.product && errors.product)}
                          helperText={touched.product && errors.product}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Autocomplete
                      {...getFieldProps("model")}
                      onChange={(event, value) => setFieldValue("model", value)}
                      options={["A1", "A3"]}
                      renderInput={(props) => (
                        <TextField
                          label="Modelo"
                          {...props}
                          fullWidth
                          error={Boolean(touched.model && errors.model)}
                          helperText={touched.model && errors.model}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Autocomplete
                      {...getFieldProps("media")}
                      onChange={(event, value) => setFieldValue("media", value)}
                      disabled={isA1}
                      options={isA1 ? a1MediaOption : a3MediaOptions}
                      renderInput={(props) => (
                        <TextField
                          label="Mídia"
                          {...props}
                          fullWidth
                          error={Boolean(touched.media && errors.media)}
                          helperText={touched.media && errors.media}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Autocomplete
                      {...getFieldProps("validity")}
                      onChange={(event, value) =>
                        setFieldValue("validity", value)
                      }
                      disabled={isA1}
                      options={
                        isSafeId
                          ? isCnpj
                            ? ["1 Ano"]
                            : safeIdValidities
                          : defaultValidities
                      }
                      renderInput={(props) => (
                        <TextField
                          label="Validade"
                          {...props}
                          fullWidth
                          error={Boolean(touched.validity && errors.validity)}
                          helperText={touched.validity && errors.validity}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      {...getFieldProps("emission")}
                      onChange={(event, value) =>
                        setFieldValue("emission", value)
                      }
                      options={emissionOptions()}
                      renderInput={(props) => (
                        <TextField
                          label="Emissão"
                          {...props}
                          fullWidth
                          error={Boolean(touched.emission && errors.emission)}
                          helperText={touched.emission && errors.emission}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={{ xs: 2, sm: 3 }}
                  >
                    <Grid item xs={12} sm={5} md={2}>
                      <Button
                        type="submit"
                        size="large"
                        fullWidth
                        variant="contained"
                        sx={{ color: "white" }}
                      >
                        <Typography
                          variant="button"
                          color="white"
                          fontSize="1em"
                        >
                          Prosseguir
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormikForm>
          </FormikProvider>
        </Box>
      </Container>
    </>
  );
};

export default Form;
