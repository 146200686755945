import { useEffect, useState } from "react";
import { Typography, Grid, TextField, InputLabel, FormControlLabel, Checkbox } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import { PreventConsultProps } from "../model";

const PreventConsult: React.FC<PreventConsultProps> = ({
  makeConsult,
  setMakeConsult,
  preventConsult,
  isCnpj,
  initialValues,
}) => {
  const [validationSchema, setValidationSchema] = useState({
    CPF: Yup.string()
      .required("O CPF é Obrigatório.")
      .length(14, "O CPF deve ter 11 números. "),
    DataNascimento: Yup.string().required(
      "A data de Nascimento é Obrigatória."
    ),
  });

  const rootSchema = Yup.object().shape({
    ...validationSchema,
  });

  const formik = useFormik({
    validationSchema: rootSchema,
    initialValues: initialValues,
    onSubmit: (values) => preventConsult(values),
  });

  const { handleSubmit, getFieldProps, errors, touched } = formik;

  useEffect(() => {
    if (makeConsult) {
      handleSubmit();
      setMakeConsult(false);
    }
  }, [makeConsult]);

  useEffect(() => {
    if (isCnpj) {
      setValidationSchema({
        ...validationSchema,
        ...{
          CNPJ: Yup.string()
            .required("O CNPJ é Obrigatório.")
            .length(18, "O CNPJ deve ter 14 números."),
        },
      });
    }
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit} autoComplete="off" noValidate>
      <Grid
          container
          direction="row"
          spacing={3}
          alignItems="flex-start"
          alignContent="start"
          justifyContent="flex-start"
          // sx={{ width: "100%" }}
          minHeight={{ xs: "none", md: 420 }}
        >
          <Grid item>
            <Typography textAlign="center" variant="h4" fontWeight={700} marginBottom={3}>
              Para prosseguir, Insira as informações a seguir
            </Typography>
          </Grid>
          {isCnpj && (
            <Grid item xs={12} sm={6} >
              <InputMask
                mask={"99.999.999/9999-99"}
                maskChar=""
                helperText={touched.CNPJ && errors.CNPJ}
                error={Boolean(touched.CNPJ && errors.CNPJ)}
                {...getFieldProps("CNPJ")}
              >
                {(inputprops: any) => (
                  <TextField fullWidth label="CNPJ" {...inputprops} />
                )}
              </InputMask>
            </Grid>
          )}
          <Grid item xs={12} sm={6} >
            <InputMask
              mask="999.999.999-99"
              maskChar=""
              helperText={touched.CPF && errors.CPF}
              error={Boolean(touched.CPF && errors.CPF)}
              {...getFieldProps("CPF")}
            >
              {(inputprops: any) => (
                <TextField fullWidth label="CPF" {...inputprops} />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={6} >
            <InputMask
              mask="99/99/9999"
              maskChar=""
              {...getFieldProps("DataNascimento")}
              error={Boolean(touched.DataNascimento && errors.DataNascimento)}
              helperText={touched.DataNascimento && errors.DataNascimento}
            >
              {(props: any) => (
                <TextField {...props} fullWidth label="Data de Nascimento" />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={6}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native" >
              POSSUI CNH?
            </InputLabel>
            <Checkbox {...getFieldProps("cnh")} />Sim
             
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default PreventConsult;
