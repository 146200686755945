import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Radio,
  RadioGroup,
  Paper,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { PaymentMethodsProps } from "../model";

const options = [
  {
    label: "Boleto",
    icon: "mdi:barcode-scan",
  },
  {
    label: "Cartão de Crédito",
    icon: "material-symbols:credit-card-outline",
  },
  {
    label: "Pix",
    icon: "fa6-brands:pix",
  },
];

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  initialValues,
  handleSubmitPaymentMethod,
  setMakeSubmitPaymentMethod,
  makeSubmitPaymentMethod,
  paymentOptions,
  previewData,
}) => {
  const { cardValues, method } = initialValues;
  const { metodo_id, metodo_nome, metodo_vezes } = method;
  const [currentMethod, setCurrentMethod] = useState({
    metodo_id,
    metodo_nome,
    metodo_vezes,
  });

  useEffect(() => {
    if (makeSubmitPaymentMethod) {
      handleSubmitPaymentMethod({ cardValues, method: currentMethod });
    }
    setMakeSubmitPaymentMethod(false);
  }, [makeSubmitPaymentMethod]);

  return (
    <Box sx={{ padding: 3, width: "100%" }}>
      <RadioGroup
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "start",
        }}
        onChange={(event, value) => {
          const selectedMethod = paymentOptions.filter(
            (option) => option.metodo_nome === value
          )[0];
          const { metodo_id, metodo_nome } = selectedMethod;

          const metodo_vezes = 1;

          setCurrentMethod({
            metodo_id,
            metodo_nome,
            metodo_vezes,
          });
        }}
        value={currentMethod.metodo_nome}
      >
        {paymentOptions.map(({ metodo_id, metodo_nome }) => (
          <Paper
            key={metodo_id}
            variant="outlined"
            sx={{
              width: "100%",
              padding: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Stack direction="row" sx={{ width: "100%" }}>
              <FormControlLabel
                sx={{ width: "100%" }}
                value={metodo_nome}
                control={<Radio />}
                label={
                  <Box
                    id="labelContainer"
                    sx={{ marginLeft: 2, width: "100%" }}
                  >
                    <Typography className="sale-completed" fontSize="0.875rem" fontWeight={600}>
                      {metodo_nome}
                    </Typography>
                  </Box>
                }
              />
              {metodo_nome === "Boleto" && (
                <Icon icon={options[0].icon} width={50} height={50} />
              )}
              {metodo_nome === "Cartão de Crédito" && (
                <Icon icon={options[1].icon} width={50} height={50} />
              )}
              {metodo_nome === "Pix" && (
                <Icon icon={options[2].icon} width={50} height={50} />
              )}
            </Stack>
          </Paper>
        ))}
      </RadioGroup>
    </Box>
  );
};

export default PaymentMethods;
