import {
  NavLink as RouterLink,
  useLocation,
  NavLinkProps,
} from "react-router-dom";
// material
import { styled } from "@material-ui/core/styles";
import { AppBar, Toolbar, Container } from "@material-ui/core";
// hooks
import useOffSetTop from "../../hooks/useOffSetTop";
// components
import Logo from "assets/images/logo.png";
import { MenuHidden } from "../../components/@material-extend";
//
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import navConfig from "./MenuConfig";
import { IconButton, Link, Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    height: APP_BAR_DESKTOP,
  },
}));

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

const SOCIALS = [
  {
    name: "FaceBook",
    iconString: "logos:facebook",
    link: "https://www.facebook.com/passwordcertificacaodigital",
  },
  {
    name: "Instagram",
    iconString: "skill-icons:instagram",
    link: "https://www.instagram.com/passwordcertificacaodigital/",
  },
  {
    name: "Whatsapp",
    iconString: "bi:whatsapp",
    link: "https://api.whatsapp.com/send/?phone=558007999901&text=Vim+pelo+seu+site+e+gostaria+de+emitir+meu+certificado+digital.&type=phone_number&app_absent=0",
  },
  {
    name: "Google",
    iconString: "logos:google-icon",
    link: "https://www.google.com/search?q=password+certficado+digital&sxsrf=APwXEddRnF0Z_aPJbp_KhL4mmLYurOtmQg%3A1684415423225&ei=vyNmZOeyDaqu5OUP8fSG8Ak&ved=0ahUKEwinjtK3-P7-AhUqF7kGHXG6AZ4Q4dUDCA8&uact=5&oq=password+certificado+digital&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIHCCMQigUQJzILCC4QgAQQxwEQrwEyBggAEBYQHjIGCAAQFhAeMgIIJjIZCC4QgAQQxwEQrwEQlwUQ3AQQ3gQQ4AQYAToOCC4QgAQQxwEQrwEQsAM6CQgAEAcQHhCwAzoJCAAQBRAeELADOgsIABAIEB4QDxCwA0oECEEYAVDpBVjmDmCKEWgBcAB4AIABlgGIAfsIkgEDMC44mAEAoAEByAEFwAEB2gEGCAEQARgU&sclient=gws-wiz-serp#lrd=0x93a781b215500e39:0x5c469d97377c272f,1,,,,",
  },
];

export type MenuItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  to?: NavLinkProps;
  children?: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
};

export type MenuProps = {
  isOffset: boolean;
  isHome: boolean;
  navConfig: MenuItemProps[];
};

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = false;

  return (
    <AppBar
      sx={{
        boxShadow: "0 2px 2px #00000026",
        bgcolor: "white",
        boxSizing: "border-box",
        overflow: "hidden",
        height: "auto",
      }}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: "background.default",
            height: { md: APP_BAR_DESKTOP - 16 },
          }),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row">
            <RouterLink to="/">
              <img height={70} src={Logo} alt="Logo Password" />
            </RouterLink>
            <MenuHidden width="mdDown">
              {SOCIALS.map((social) => (
                <IconButton
                  component={Link}
                  href={social.link}
                  target="blank"
                  key={social.name}
                  color="primary"
                  
                >
                  <Icon
                    className={social.name === "Whatsapp" ? "whatsapp-gtg" : ""}
                    color={social.name === "Whatsapp" ? "#54D62C" : ""}
                    icon={social.iconString}
                    width={20}
                    height={20}
                  />
                </IconButton>
              ))}
            </MenuHidden>
          </Stack>

          <MenuHidden width="mdDown">
            <MenuDesktop
              isOffset={isOffset}
              isHome={isHome}
              navConfig={navConfig}
            />
          </MenuHidden>

          <MenuHidden width="mdUp">
            <Stack
              component={Link}
              href="tel:08007999901"
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Icon icon="mdi:telephone-outline" width={28} color="#fda92d" />
              <Typography variant="h6" noWrap textOverflow="visible">
                0800 799 9901
              </Typography>
            </Stack>
            <MenuMobile
              isOffset={isOffset}
              isHome={isHome}
              navConfig={navConfig}
            />
          </MenuHidden>
        </Container>
      </ToolbarStyle>
      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
