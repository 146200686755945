import { Icon } from "@iconify/react";
import roundArrowRightAlt from "@iconify/icons-ic/round-arrow-right-alt";
// material
import { alpha, useTheme, styled } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  Container,
  Typography,
  LinearProgress,
} from "@material-ui/core";
// utils
import { fPercent } from "utils/formatNumber";
import mockData from "utils/mock-data";
//
import { MenuHidden } from "components/@material-extend";
import { varFadeInUp, varFadeInRight, MotionInView } from "components/animate";
import { Link } from "@mui/material";

// ----------------------------------------------------------------------

const LABEL = ["Development", "Design", "Marketing"];

const MOCK_SKILLS = [...Array(3)].map((_, index) => ({
  label: LABEL[index],
  value: mockData.number.percent(index),
}));

const RootStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
}));

// ----------------------------------------------------------------------

type ProgressItemProps = {
  progress: {
    label: string;
    value: number;
  };
};

function ProgressItem({ progress }: ProgressItemProps) {
  const { label, value } = progress;
  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mb: 1.5, display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2">{label}&nbsp;-&nbsp;</Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {fPercent(value)}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          "& .MuiLinearProgress-bar": { bgcolor: "grey.700" },
          "&.MuiLinearProgress-determinate": { bgcolor: "divider" },
        }}
      />
    </Box>
  );
}

interface AboutWhatProps {
  image1: string;
  image2: string;
  title: string;
  description: string;
  button?: {
    label: string;
    href: string;
  };
}

export default function AboutWhat({
  image1,
  image2,
  title,
  description,
  button,
}: AboutWhatProps) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.48
  )}`;

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={12}>
            <MotionInView variants={varFadeInRight}>
              <Typography variant="h2" sx={{ mb: 3 }}>
                {title}
              </Typography>
            </MotionInView>

            <MotionInView variants={varFadeInRight}>
 
              <div>
              A Política de Privacidade da PASSWORD SOLUCOES EM TECNOLOGIA LTDA (AR PASSWORD), inscrita no CNPJ sob o n° 32.076.453/0001 98 e demais empresas do grupo, foi criada para mostrar o nosso compromisso em sempre tratar os seus dados pessoais com segurança, privacidade e transparência.


              Esta Política de Privacidade descreve os dados pessoais que coletamos, como eles são usados, armazenados e compartilhados e os seus direitos em relação a esses dados. Recomendamos a leitura atenta.


              <br/>
              <br/>
              <strong>1.	OBJETIVO</strong>
              <br/>
              1.1.	A presente Política tem por finalidade reforçar o compromisso da AR PASSWORD com a sociedade, garantindo que todas as etapas das suas atividades desempenhadas estão em consonância com a Lei Geral de Proteção de Dados Pessoais.
              <br/>
              1.2.	Quando você contrata os serviços da AR PASSWORD, você nos fornece os seus dados pessoais para que possamos viabilizar a contratação de nossos produtos e serviços. Na AR PASSWORD a privacidade, segurança e transparência são valores fundamentais e sempre adotaremos as melhores práticas para garantir a confidencialidade e integridade dos seus dados pessoais.
              <br/>
              1.3.	A AR PASSWORD reconhece a importância da privacidade do ser humano e está comprometida com os princípios da boa-fé, finalidade, adequação, necessidade, livre acesso, qualidade dos dados, transparência, segurança, prevenção, não discriminação, responsabilização e prestação de contas, quando do processamento de dados pessoais. 
              <br/>
              1.4.	Mesmo após a leitura de nossa Política de Privacidade, se você tiver dúvidas, reclamações, quiser exercer seus direitos relacionados aos seus dados pessoais ou se comunicar com a AR PASSWORD sobre esse assunto, você pode entrar em contato conosco pelos nossos canais de atendimento ou contatar o nosso encarregado (DPO) pelo e-mail dpo@arpassword.com.br.
              <br/>
              <br/>
              <strong>2.	ABRANGÊNCIA </strong>
              <br/>
              2.1	Este documento abrange todos os tratamentos de dados pessoais de clientes, fornecedores, parceiros de negócios, funcionários e outros indivíduos, realizados pela AR PASSWORD e indica as diretrizes que deverão ser seguidas, seja quando da atuação como Controlador, seja quando da atuação como Operador, tanto no meio digital, quanto no meio físico. 
              <br/>
              2.2	Esta Política de Proteção de Dados Pessoais aplica-se à AR PASSWORD e demais filiais, controladas direta ou indiretamente, que conduzem negócios no âmbito Nacional e/ou Internacional.
              <br/>
              2.3	Esta Política de Privacidade é aplicável a todos os clientes do AR PASSWORD no Brasil. Nós coletamos os seus dados pessoais sempre que você:
              <br/>
              Contratar ou usar qualquer um dos nossos produtos ou serviços, adquirindo um certificado digital.
              <br/>
              Navegar no website do AR PASSWORD: https://passwordcertificacao.com.br/
              <br/>
              Navegar nas mídias sociais Instagram, Facebook, Linkedin, Twitter e Pinterest.
              <br/>
              Entrar em contato conosco pelos nossos canais de atendimento WhatsApp, e-mail e telefone.
              <br/>
              Participar de pesquisas ou promoções realizadas pela AR PASSWORD.
              <br/>
              <br/>
              <strong>3.	REFERÊNCIAS</strong>
              <br/>
              3.1	Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709, de 14 de agosto de 2018).
              <br/>
              3.2	Normativas ICP-Brasil.
              <br/>
              <br/>
              <strong>4.	DEFINIÇÕES</strong>
              <br/>
              4.1	Dados Pessoais: Qualquer informação relativa a uma pessoa natural identificada ou identificável (titular dos dados); é considerada identificável uma pessoa natural que possa ser identificada, direta ou indiretamente, em especial por referência a uma informação, como por exemplo um nome, um número de identificação, dados de localização, identificadores por via eletrônica ou a um ou mais elementos específicos da identidade física, fisiológica, genética, mental, económica, cultural ou social dessa pessoa natural. 
              <br/>
              4.2	Titular dos dados pessoais: Pessoa natural a quem se referem os dados pessoais que são objeto de tratamento, o indivíduo.
              <br/>
              4.3	Dados Pessoais Sensíveis: Trata-se de dados pessoais que são, pela sua natureza, particularmente sensíveis, vez que invadem um nível maior de privacidade do indivíduo, podendo criar riscos significativos para os direitos e liberdades fundamentais. Conforme a legislação, dados pessoais sensíveis são:
              <br/>
              (i) Dados sobre a origem racial ou étnica;
              <br/>
              (ii) Dados sobre a convicção religiosa;
              <br/>
              (iii) Dados sobre a opinião política;
              <br/>
              (iv) Dados sobre a filiação sindical;
              <br/>
              (v) Dados sobre a filiação a organização de caráter religioso, filosófico ou político;
              <br/>
              (vi) Dados referente à saúde ou à vida sexual;
              <br/>
              (vii) Dados genéticos ou biométricos, quando vinculados a uma pessoa natural.
              <br/>
              <br/>
              4.4	Tratamento: Uma operação ou um conjunto de operações efetuadas sobre dados pessoais ou sobre conjuntos de dados pessoais, por meios automatizados ou não automatizados, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
              <br/>
              4.5	Ciclo de Vida: Diz respeito às etapas de tratamento de dados, incluindo as de coleta, processamento, transferência, armazenamento, término do tratamento e descarte. 
              <br/>
              4.6	Consentimento: Manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento de seus dados pessoais para uma finalidade determinada.
              <br/>
              4.7	Anonimização: Utilização de meios técnicos razoáveis e disponíveis no momento do tratamento para transformar dados pessoais em dados anônimos. Neste caso, um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo. Assim, a anonimação desidentifica de forma irreversível os dados pessoais, impossibilitando a identificação usando tempo, custo e tecnologia razoáveis. Os princípios de processamento de dados pessoais não se aplicam a dados anônimos, pois não são mais dados pessoais.
              <br/>
              4.8	Agentes de tratamento: o controlador e o operador.
              <br/>
              4.9	Controlador: pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.
              <br/>
              4.10	Operador: pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.
              <br/>
              4.11	Encarregado de Tratamento de Dados Pessoais ou “DPO” - Data Protection Officer: pessoa indicada pelo controlador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados. 
              <br/>
              4.12	Autoridade Nacional de Proteção de Dados (ANPD): órgão da administração pública responsável por zelar, implementar e fiscalizar o cumprimento desta Lei. 
              <br/>
              4.13	Bloqueio: suspensão temporária de qualquer operação de tratamento, mediante guarda do dado pessoal ou do banco de dados.
              <br/>
              4.14	Eliminação: exclusão de dado ou de conjunto de dados armazenados em banco de dados, independentemente do procedimento empregado.
              <br/>
              4.15	Uso compartilhado de dados: comunicação, difusão, transferência internacional, interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes privados.
              <br/>
              4.16	Relatório de impacto à proteção de dados pessoais (RIPD): documentação do controlador que contém a descrição dos processos de tratamento de dados pessoais que podem gerar riscos às liberdades civis e aos direitos fundamentais, bem como medidas, salvaguardas e mecanismos de mitigação de risco.
              <br/>
              <br/>
              <strong>5.	DIRETRIZES GERAIS E ESPECÍFICAS</strong>
              <br/>            
              5.1	O comprometimento da AR PASSWORD com a legislação de proteção de dados pessoais existe em todas as etapas do processamento das informações, integrando transparência, privacidade e proteção às possibilidades de tratamento dos dados pessoais, sempre respeitando o ciclo de vida das informações, tanto no meio físico, quanto no meio digital.
              <br/> 
              5.2	A AR PASSWORD dispõe de sistemas e equipamentos destinados a garantir a segurança dos dados pessoais tratados, criando e atualizando procedimentos capazes de prevenir acessos não autorizados, perdas acidentais e/ ou destruição dos dados pessoais. A AR PASSWORD manterá a integridade, a confidencialidade e a relevância dos dados pessoais com base no propósito do processamento. 
              <br/> 
              5.3	Mecanismos de segurança adequados, projetados para proteger os dados pessoais, devem ser usados para evitar que estes sejam roubados, mal utilizados ou violados.
              <br/> 
              5.4	A responsabilidade de garantir o processamento adequado de dados pessoais é de todos que trabalham para ou com a AR PASSWORD e que possuem acesso a dados pessoais processados pela AR PASSWORD. 
              <br/> 
              5.5	A AR PASSWORD realiza, periodicamente, treinamentos referentes às questões de proteção de dados pessoais, com vista a assegurar o conhecimento por parte dos colaboradores acerca das obrigações que lhes são impostas neste âmbito. 
              <br/> 
              5.6	A AR PASSWORD, de forma lícita, realizará os possíveis tratamentos de dados: coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
              <br/> 
              <br/> 
              <strong>6.	Quais dados pessoais são coletados pelo AR PASSWORD</strong>
              <br/> 
              6.1 Ao solicitar, contratar ou usar os produtos ou serviços da AR PASSWORD, você nos fornece, e nós coletamos, alguns dados pessoais relacionados a você. Desde o momento em que você interage com a AR PASSWORD, nós coletamos os seus dados pessoais
              <br/> 
              6.2 Ao aceitar os termos desta Política de Privacidade, você concorda expressamente em fornecer apenas dados pessoais verdadeiros, atuais e precisos e em não alterar a sua identidade ou seus dados pessoais de qualquer forma no acesso e na utilização dos nossos produtos ou serviços. Você será o único responsável pelas informações falsas, desatualizadas ou imprecisas que fornece diretamente para AR PASSWORD.
              <br/> 
              6.3 Esses são os seus dados pessoais tratados pelo AR PASSWORD, divididos por categorias:
              <br/> 
              (i)	Dados cadastrais, tais como: nome completo, documentos de identificação, número do CPF, nacionalidade, endereço, data de nascimento, filiação, gênero, entre outros.
              <br/> 
              (ii)	Dados de contato, como telefone e e-mail;   
              <br/> 
              (iii)	Empresa em que trabalha;
              <br/> 
              (iv)	Profissão;
              <br/> 
              (v)	Informações empregatícias administrativas: currículo, formulário do candidato, avaliações, registros de segurança, registros de ausência, prontuário médico, relatório de acidentes, análise de desenvolvimento pessoal, números de identificação oficiais, registro de habilidade, carteira de motorista; e
              <br/> 
              (vi)	Dados biométricos, tais como a fotografia do seu documento de identificação, do seu rosto e digitais.
              <br/> 
              <br/> 
              <strong>7.	UTILIZAÇÃO DOS DADOS PESSOAIS COLETADOS</strong>
              <br/>
              7.1 A AR PASSWORD utiliza os seus dados pessoais para poder prestar um serviço de alta qualidade e oferecer os melhores produtos a você. Detalhamos a seguir as finalidades para as quais utilizamos os seus dados pessoais:
              Prestação dos serviços e oferecimento dos produtos contratados;
              <br/>
              •	Identificação, autenticação e verificação de requisitos para contratação dos serviços e produtos do AR PASSWORD;
              <br/>
              •	Atendimento de solicitações e dúvidas;
              <br/>
              •	Contato por telefone, e-mail, SMS, WhatsApp, ou outros meios de comunicação
              <br/>
              •	Marketing, prospecção, pesquisas de mercado, de opinião e promoção dos nossos produtos e serviços, ou de nossos parceiros, inclusive com viabilização de ofertas e envio de informações sobre produtos, serviços, novidades, funcionalidades, conteúdos, notícias e demais eventos relevantes para a manutenção do relacionamento com você;
              <br/>
              •	Prevenção e resolução de problemas técnicos ou de segurança;
              <br/>
              •	Investigações e medidas de prevenção e combate a ilícitos, fraudes, crimes financeiros e garantia da segurança dos clientes da AR PASSWORD;
              <br/>
              •	Exercício regular de direitos da AR PASSWORD, inclusive apresentando documentos em processos judiciais e administrativos, se necessário;
              <br/>
              •	Colaboração ou cumprimento de ordem judicial, de autoridade competente ou de órgão fiscalizador; e 
              <br/>
              •	Cumprimento de obrigação legal ou regulatória.
              <br/>
              7.2 Dados biométricos
              <br/>
              Finalidades:
              <br/>
              Cumprimento de obrigação regulatória, prevenção à fraude e garantia da sua segurança nos processos de identificação, validação e emissão de certificado digital.
              <br/>
              7.3 Dados de colaboradores
              <br/>
              Finalidades:
              <br/>
              Os tratamentos de dados dos empregados da AR PASSWORD terão as seguintes finalidades: o cumprimento de obrigações legais ou regulatórias, a manutenção dos contratos de trabalho, o exercício regular de direitos em processo judicial, administrativo ou arbitral.
              <br/>
              7.4 Os empregados, colaboradores e clientes PASSWORD, quando da coleta de dados, quando necessário legalmente, serão questionados sobre o consentimento, serão informados sobre o ciclo de vida dos dados, bem como sobre as possibilidades de transferência de tais informações com outras empresas.
              <br/>
              8.	Quais são as Bases Legais aplicáveis (hipótese de tratamento)
              <br/>
              8.1 A AR PASSWORD preocupa-se especialmente com os direitos das crianças e adolescentes, razão pela qual a coleta de dados pessoais de menores de 18 anos estará condicionada ao consentimento específico e em destaque dado por pelo menos um dos pais ou pelo responsável legal. 
              <br/>
              8.2 A AR PASSWORD garantirá que os dados pessoais apenas serão tratados de acordo com as finalidades para as quais foram originalmente coletados, implicando na existência de um propósito antes da atividade de coleta.
              <br/>
              8.3 Caso a AR PASSWORD necessite processar os dados pessoais recolhidos para outra finalidade, a AR PASSWORD deverá obter o consentimento dos seus titulares de dados por meio documento redigido de forma clara e concisa. A solicitação deverá incluir a finalidade original para a qual os dados foram recolhidos e a(s) finalidade(s) nova(s) ou adicional(ais). A solicitação também deve incluir o motivo da mudança na(s) finalidade(s).
              <br/>
              8.4 Compete à AR PASSOWORD identificar a possibilidade de aplicar o legítimo interesse para o tratamento de dados pessoais. No entanto, tal fundamentação legal será utilizada apenas quando devidamente realizado o relatório de impacto à proteção de dados pessoais e autorizado pelo Encarregado.
              <br/>
              8.5 AR PASSWORD, diante da necessidade de cumprimento de obrigações legais ou regulatórias, manutenção e execução de contratos, exercício regular de direitos em processo judicial, administrativo ou arbitral ou por meio do legítimo interesse, poderá compartilhar dados com outras empresas e até mesmo com a administração pública.
              <br/>
              8.6	A AR PASSWORD buscará meios para que os dados pessoais armazenados eletronicamente sejam protegidos por senhas e/ou criptografados.
              <br/>
              <br/>
              <strong>9.	Compartilhamento de dados pessoais</strong>
              <br/>
              9.1 A AR PASSWORD poderá compartilhar os seus dados caso você solicite. O compartilhamento dos seus dados também poderá ser feito com empresas do grupo da AR PASSWORD, com terceiros parceiros e com autoridades e órgãos reguladores para diferentes finalidades, quando necessário. 
              <br/>
              9.2 Sempre que efetuado, o compartilhamento de dados será realizado dentro dos limites e propósitos dos nossos negócios e de acordo com o que autoriza a legislação aplicável.
              <br/>
              9.3 Abaixo preparamos um resumo dividido por categorias com os tipos de fornecedores com quem normalmente compartilhamos seus dados pessoais:
              <br/>
              9.4 Outras empresas do grupo da AR PASSWORD
              <br/>
              Finalidades do compartilhamento:
              <br/>
              •	Prestação de serviços e oferecimento dos produtos contratados para o cliente
              <br/>
              •	Operacionalização e oferta de novos serviços e produtos
              <br/>
              •	Marketing, prospecção, pesquisas de mercado, de opinião e promoção dos nossos produtos e serviços
              <br/>
              •	Proteção ao crédito, incluindo concessão de crédito e aumento de limite
              <br/>
              •	Prevenção e resolução de problemas técnicos ou de segurança
              <br/>
              •	Investigações e medidas de prevenção e combate a ilícitos, fraudes, crimes financeiros e garantia da segurança dos clientes da AR PASSWORD e do sistema financeiro
              <br/>
              •	Exercício regular de direitos da AR PASSWORD
              <br/>
              •	Cumprimento de obrigação legal ou regulatória
              <br/>
              9.5 Autoridades e órgãos reguladores
              <br/>
              Finalidades do compartilhamento:
              <br/>
              •	Investigações e medidas de prevenção e combate a ilícitos, fraudes, crimes financeiros e garantia da segurança dos clientes da AR PASSWORD
              <br/>
              •	Exercício regular de direitos da AR PASSWORD, inclusive apresentando documentos em processos judiciais e administrativos, se necessário
              <br/>
              •	Cumprimento de ordem judicial, atendimento de solicitação de autoridade competente ou órgão fiscalizador
              <br/>
              •	Cumprimento de obrigação legal ou regulatória
              <br/>
              9.5 Mediante sua solicitação
              <br/>
              Finalidades do compartilhamento:
              <br/>
              •	Garantir a transparência na nossa relação com você
              <br/>
              •	Envio de notificações não obrigatórias por e-mails, push, WhatsApp e SMS
              <br/>
              Além disso, ao utilizar nosso aplicativo ou navegar em nossos websites, você pode ser redirecionado para sites ou aplicativos de terceiros. Depois que você for redirecionado para um site ou aplicativo de terceiros, as práticas de privacidade serão regidas pelas políticas de privacidade e pelos termos de uso desses terceiros. Não podemos controlar ou nos responsabilizar pelas práticas e conteúdo de privacidade de terceiros. Leia atentamente as políticas de privacidade aplicáveis para entender como eles coletam e processam seus dados.
              <br/>
              <br/>
              <strong>10.	Retenção e exclusão dos seus dados pessoais</strong>
              <br/>
              10.1 Enquanto você for um Cliente ou Prospecto da AR PASSWORD, durante o uso dos nossos produtos e serviços e por todo o período em que a AR PASSWORD armazenar seus dados pessoais, eles serão mantidos em ambiente seguro e controlado.
              <br/>
              10.2 Assim, e sempre que não exista uma exigência legal específica, os dados serão armazenados e conservados apenas pelo período necessário para as finalidades para as quais foram recolhidos.
              <br/>
              10.3 O término do tratamento e, consequentemente, o descarte definitivo dos dados pessoais
              ocorrerá nas seguintes situações:
              <br/>
              (i) Verificação de que a finalidade do tratamento foi alcançada;
              <br/>
              (ii) Que o prazo para o tratamento do dado especificado expirou;
              <br/>
              (iii) Quando o titular dos dados pessoais revogar o consentimento;
              <br/>
              (iv) Quando a Autoridade Nacional de Proteção de Dados determinar.
              <br/>
              10.4	A conservação dos dados pessoais está autorizada nas seguintes situações:
              <br/>
              (i) Cumprimento de obrigação legal ou regulatória pelo controlador;
              <br/>
              (ii) Estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;
              <br/>
              (iii) Transferência a terceiro, desde que respeitados os requisitos de tratamento de dados da legislação nacional; ou
              <br/>
              (iv) Uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.
              <br/>
              <br/>
              <strong>11.	Seus direitos como titular de dados pessoais</strong>
              <br/>
              Você, como titular de dados pessoais, pode exercer seus direitos frente aos controladores dos seus dados pessoais. Nós disponibilizamos os mecanismos detalhados abaixo para que você possa entender de forma clara e transparente como exercer seus direitos e nossa equipe estará pronta para atender eventuais solicitações.
              <br/>
              11.1 Confirmação da existência de tratamento de dados pessoais
              <br/>
              O fato de você ser Cliente do AR PASSWORD já significa que nós fazemos o tratamento dos seus dados pessoais, mesmo que esse tratamento seja, entre outros, o armazenamento de dados pessoais em ambiente seguro e controlado. Você pode solicitar ao AR PASSWORD que confirme se realiza o tratamento dos seus dados pessoais.
              <br/>
              11.2 Acesso aos dados pessoais
              <br/>
              Você pode solicitar que a AR PASSWORD informe e forneça os dados pessoais que possui em relação a você.
              <br/>
              11.3 Correção de dados pessoais incompletos, inexatos ou desatualizados
              <br/>
              Se você verificar que os seus dados pessoais estão incompletos, inexatos ou desatualizados, você pode pedir a correção ou complementação. Para isso, caso não seja possível realizar a alteração pelo próprio site da AR PASSWORD, você precisará enviar um documento que comprove a forma correta e atual do dado pessoal.
              <br/>
              11.4 Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a LGPD
              <br/>
              Caso qualquer dado pessoal seja tratado de forma desnecessária, em excesso para a finalidade a que se destina ou em desconformidade com a LGPD, você pode solicitar que a AR PASSWORD anonimize, bloqueie ou elimine esses dados, desde que fique efetivamente constatado o excesso, a falta de necessidade ou a desconformidade com a lei.
              <br/>
              11.5 Eliminação dos dados pessoais tratados com o consentimento
              <br/>
              Caso você tenha dado o consentimento para tratamento dos seus dados pessoais para finalidades específicas (e não necessárias para a prestação dos nossos serviços ou entrega dos nossos produtos), você poderá solicitar a eliminação desses dados pessoais, como por exemplo, dados pessoais obtidos a partir da sua geolocalização para indicação do local onde foram realizadas suas compras.
              <br/>
              11.6 Informação das empresas com as quais a AR PASSWORD compartilhou ou das quais recebeu seus dados pessoais
              <br/>
              Você pode solicitar que a AR PASSWORD informe com quais terceiros compartilhou ou de quem recebeu seus dados pessoais.
              <br/>
              11.7 Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa
              <br/>
              Caso seu consentimento seja necessário para acessar ou usar determinado produto ou serviço, você pode pedir que a AR PASSWORD esclareça se é possível fornecer esse produto ou prestar esse serviço sem o seu consentimento para o tratamento dos seus dados pessoais, ou quais são as consequências de não fornecer o consentimento para este caso.
              <br/>
              11.7 Revogação do consentimento
              <br/>
              Caso você tenha dado o seu consentimento para tratamento dos seus dados pessoais, você pode solicitar a revogação desta autorização. A revogação do consentimento pode resultar na impossibilidade de uso de algumas funcionalidades do aplicativo, ou até mesmo no encerramento dos serviços prestados, mas não impede o uso de (i) dados anonimizados; e (ii) dados cujo tratamento esteja baseado em outra hipótese legal prevista na LGPD.
              <br/>
              11.8 Portabilidade e direito de petição
              <br/>
              Quando regulamentado o direito de portabilidade pela Autoridade Nacional de Proteção de Dados (ANPD), você poderá solicitar para a AR PASSWORD a portabilidade dos seus dados pessoais a outro prestador de serviços. Além disso, com a constituição da ANPD, você terá também o direito de peticionar em relação aos seus dados perante a autoridade nacional.  
              Caso você deseje exercer qualquer um desses direitos, entre em contato pelo e-mail dpo@arpassword.com.br. Para efetivarmos os seus direitos, podemos solicitar a comprovação da sua identidade, como medida de segurança e prevenção à fraude.
              <br/>
              <br/>
              <strong>12.	Registro de atividades</strong>
              <br/>
              Podemos registrar as atividades que você realiza quando utiliza nosso site ou blogs, criando, quando possível e aplicável, logs (registros de atividades efetuadas nos sites e aplicativos e serviços) que conterão: o endereço IP, acesso e ações realizadas por você no serviço disponibilizado, data e hora de cada ação realizada e informações sobre o dispositivo utilizado, tais como a versão de sistema operacional, navegador e geolocalização.
              Também podemos utilizar certas tecnologias, próprias ou de terceiros, de monitoramento das atividades realizadas enquanto você acessa nossos sites tais como:
              Cookies: são arquivos de internet que armazenam de forma temporária o que você está visitando na rede. A AR PASSWORD possui cookies em seus websites e recebe informações de parceiros a respeito de cookies inseridos nos seus respectivos websites. Os cookies podem ser utilizados para diversos propósitos, incluindo lembrar-se de você e de suas preferências, persistir informações relacionadas a suas atividades no site visitado, ou coletar informações que podem ser usadas para oferecer conteúdo de uma forma personalizada. Nossos sites também podem utilizar objetos armazenados localmente para fornecer determinado conteúdo, tais como vídeo sob demanda, videoclipes ou animação.
              Nós temos cookies de terceiros ativados em nosso site. As práticas de privacidade serão regidas pelas políticas de privacidade e pelos termos de uso desses terceiros, de forma que não podemos controlar ou nos responsabilizar pelas práticas e conteúdo de privacidade de terceiros. Por isso destacamos que você pode, a qualquer momento, bloquear o uso dos cookies ativando uma configuração no seu navegador de internet e sua capacidade de limitar os cookies estará sujeita às configurações e limitações do seu navegador. Você também pode excluir os cookies existentes através das mesmas configurações do seu navegador de internet. Caso você opte por desativar os cookies, você poderá continuar navegando nos sites e nos blogs, mas algumas partes das páginas poderão deixar de funcionar.
              Ferramentas de analytics: Essas ferramentas podem coletar informações como a forma que você visita um site, incluindo quais páginas e quando você visita tais páginas, além de outros sites que foram visitados antes, entre outras.
              Todas as tecnologias utilizadas por nós sempre respeitarão os termos desta Política de Privacidade.
              <br/>
              13.	Medidas de segurança
              <br/>
              A AR PASSWORD usa diversos tipos de medidas de segurança para garantir a integridade de seus dados pessoais, como padrões de segurança de informação praticados pela indústria quando coleta e armazena dados pessoais.
              Os dados pessoais podem, ainda, ser armazenados por meios de tecnologia de cloud computing e outras tecnologias que surjam futuramente, visando sempre a melhoria e aperfeiçoamento de nossos serviços e segurança.
              Tratamos a segurança dos seus dados pessoais com o máximo de cuidado, utilizando padrões e melhores práticas adotados no mercado. Temos uma equipe robusta, altamente qualificada e responsável por garantir que o AR PASSWORD adote as melhores práticas de segurança, dentre elas:
              <br/>
              •	Múltiplo fator de autenticação para acesso às informações;
              <br/>
              •	Segurança como código, a fim de viabilizar automações e respostas rápidas e eficientes para eventos de segurança no ambiente tecnológico;
              <br/>
              •	Criptografia para dados em repouso, em trânsito e em uso, para garantir a integridade das informações;
              <br/>
              •	Monitoramento contínuo do ambiente; e
              <br/>
              •	Auditorias periódicas.
              <br/>
              Recomendamos ainda que você nunca compartilhe sua senha com ninguém, ela é pessoal e intransferível, e sempre tome cuidado ao postar seus dados pessoais em redes sociais ou qualquer outro ambiente público. 
              <br/>
              <br/>
              <strong>14.	Aceitação da Política de Privacidade</strong>
              <br/>
              Ao ler esta Política de Privacidade e clicar, ao final, em "Eu li, estou ciente das condições de tratamento dos meus dados pessoais e forneço meu consentimento, quando aplicável, conforme descrito nesta Política de Privacidade.", você consente com o tratamento dos dados pessoais nas formas aqui indicadas.
              Vale lembrar que o tratamento de seus dados pessoais é condição necessária para que possamos prestar nossos serviços e entregar nossos produtos para você. Caso você tenha dúvidas sobre qualquer um dos termos explicados aqui, estamos à disposição em nossos canais de atendimento para ajudá-lo.
              <br/>
              15.	Alterações a essa Política de Privacidade
              <br/>
              A AR PASSWORD poderá alterar esta Política de Privacidade a qualquer tempo. Toda vez que alguma condição relevante desta Política de Privacidade for alterada, essas alterações serão válidas, eficazes e vinculantes após a nova versão ser divulgada no nosso site ou enviada por e-mail para você.
              Prezamos sempre pela transparência: toda vez que uma alteração relevante for feita, nós enviaremos um comunicado a você indicando a nova versão vigente. Ao continuar a usar nossos produtos e serviços após uma alteração na Política de Privacidade, você concordará com as novas condições – mas você sempre pode manifestar a sua discordância por meio dos nossos canais de atendimento, se for o caso.
              Nas hipóteses em que as alterações a esta Política de Privacidade resultarem em mudanças nas práticas de tratamento de dados pessoais que dependam do seu consentimento (veja o item 12 acima), solicitaremos o seu consentimento com os novos termos da Política de Privacidade em relação ao tratamento de dados e finalidades indicados.
              <br/>
              16.	Fale Conosco
              <br/>
              Se após a leitura desta Política de Privacidade você ainda tiver qualquer dúvida, ou por qualquer razão precisar se comunicar conosco para assuntos envolvendo os seus dados pessoais, você pode entrar em contato pelos canais abaixo:
              <br/>
              •	Encarregado (DPO): E-mail: dpo@arpassword.com.br
              <br/>
              Estamos sempre à disposição para esclarecer suas dúvidas e colocar você no controle dos seus dados pessoais.
              <br/>
              <br/>
              <strong>17.	CONFIDENCIALIDADE</strong>
              <br/>
              Apesar deste documento ser de propriedade exclusiva da EMPRESA, deverá ser de leitura obrigatória dos empregados, prestadores de serviços, consultores e colaboradores temporários.
              </div>





            </MotionInView>

            {button && (
              <MotionInView variants={varFadeInRight}>
                <Button
                  component={Link}
                  href={button.href}
                  variant="outlined"
                  color="inherit"
                  size="large"
                  endIcon={
                    <Icon icon={roundArrowRightAlt} width={24} height={24} />
                  }
                  sx={{ mt: 3 }}
                >
                  {button.label}
                </Button>
              </MotionInView>
            )}
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
