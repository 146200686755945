import {
  AboutHero,
  AboutWhat,
  AboutTeam,
  AccordionComponent,
} from "../../../adapted-components";
import { RootStyle } from "./styles";
import AccordionLgpd from "../../../adapted-components/AccordionLgpd";
import SimpleAccordion, {
  AccordionComponentProps,
} from "../../../adapted-components/SimpleAccordion";

const currentEmissionListData = [
  {
    id: "videoconferencia",
    value: "panel1",
    heading: "Data mapping (inventário de dados). ",
    detail:
      "Foi realizado, um mapeamento dos dados pessoais de funcionários e de clientes que a AR coleta, onde é armazenado, retenção e responsáveis. O data mapping é o primeiro passo para iniciar a adequação a LGPD, não é possível iniciar a adequação, sem ter a visão do fluxo dos dados.",
    steps: [
    ],
    image: "https://media-public.canva.com/Xc9F4/MAEU9UXc9F4/2/tl.png",
  },
  {
    id: "presencial",
    value: "panel2",
    heading: "Elaboração e publicação da Política de Privacidade",
    detail:
      "A AR elaborou uma política geral de privacidade de dados, para mostrar o seu compromisso em sempre tratar os seus dados pessoais com segurança, privacidade e transparência. A política de privacidade está disponível no site da própria AR.",
    steps: [
    ],
    image: "https://media-public.canva.com/Xc9F4/MAEU9UXc9F4/2/tl.png",
  },
  {
    id: "online",
    value: "panel3",
    heading: "Treinamento e Conscientização. ",
    detail:
      "A AR realizou treinamento para todos os seus agentes de registro e gestores a respeito da LGPD, o objetivo foi conscientizar todos que tratam, possuem acesos aos dados, sobre o que é a Lei e como manter os dados coletados com segurança. Buscando evitar vazamentos de dados.",
    steps: [
    ],
    image: "https://media-public.canva.com/Xc9F4/MAEU9UXc9F4/2/tl.png",
  },
  {
    id: "renovacao",
    value: "panel4",
    heading: "Relatório de Impacto à Proteção de Dados Pessoais",
    detail:
      "Foi elaborado um modelo padrão, sempre que em qualquer operação que envolva o tratamento de dados pessoais que possa gerar riscos às liberdades civis e aos direitos fundamentais, será realizada a descrição dos processos para mitigação de riscos e, concomitantemente, de responsabilidades.",
    steps: [
      // "1. Escolha do certificado",
      // "2. Validação do certificado válido já existente",
      // "3. Compra do certificado escolhido",
      // "4. Emissão",
    ],
    image: "https://media-public.canva.com/Xc9F4/MAEU9UXc9F4/2/tl.png",
  },
];

const View: React.FC = () => {
  return (
    <RootStyle title="e-CPF">
      <AboutWhat
        {...{
          image1: "static/page-images/e_cpf_about_what_1.svg",
          image2: "static/page-images/e_cpf_about_what_2.svg",
          title: "Lei Geral de Proteção de Dados Pessoais (LGPD)",
          description:
            "A AR PASSWORD da AC SAFEWEB, está se preparando para atender a Lei Geral de Proteção de Dados Pessoais (LGPD), a AR realizou um assessment inicial para adequação. Mesmo possuindo bases legais e toda uma regulamentação própria para a coleta dos dados pessoais, no processo de validação e emissão do certificado digital. A AR se prepara para o cumprimento da Lei e evitar possíveis multas e sanções da Agência Nacional de Proteção de Dados (ANPD).",
          button: {
            href: "/privacy",
            label: "Política de Privacidade",
          },
        }}
      />

      <AccordionLgpd items={currentEmissionListData} />
      {/* <AccordionComponent items={currentEmissionListData} /> */}


 
    </RootStyle>
  );
};

export default View;
