import { configureStore } from "@reduxjs/toolkit";
import checkoutReducer from "./checkoutSlice";
import conclusionSlice from "./conclusionSlice";

export default configureStore({
  reducer: {
    checkout: checkoutReducer,
    conclusion: conclusionSlice,
  },
});
