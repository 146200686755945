import { Icon } from "@iconify/react";
import { useState } from "react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { styled } from "@material-ui/core/styles";
import {
  Box,
  Container,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
// utils
import mockData from "../utils/mock-data";
// components
import Page from "../components/Page";
import { PATH_PAGE } from "../routes/paths";
import HeaderBreadcrumbs from "../components/HeaderBreadcrumbs";
//
import Block from "components/Block";
import { Stack } from "@mui/material";

// ----------------------------------------------------------------------

const MOCK_ACCORDIONS = [...Array(4)].map((_, index) => ({
  id: mockData.id(index),
  value: `panel${index + 1}`,
  heading: `Accordion ${index + 1}`,
  subHeading: mockData.text.title(index),
  detail: mockData.text.description(index),
}));

const RootStyle = styled(Page)(({ theme }) => ({
  // paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(15),
})); 

export interface AccordionComponentProps {
  id: string | number;
  value: string;
  heading: string;
  subHeading?: string;
  details?: { caption: string; list?: string[] }[];
  image?: string;
}

// ----------------------------------------------------------------------

export default function SimpleAccordion({
  items,
  title,
}: {
  items: AccordionComponentProps[];
  title: string;
}) {
  const [controlled, setControlled] = useState<string | false>(false);

  const handleChangeControlled =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setControlled(isExpanded ? panel : false);
    };

  return (
    <RootStyle title="Home">
      <Typography mb={3} variant="h4" color="text.primary" align="center">
        {title}
      </Typography>
      <Container>
        {items.map((item, index: number) => (
          <Accordion
            key={item.value}
            expanded={controlled === item.value}
            onChange={handleChangeControlled(item.value)}
          >
            <AccordionSummary
              expandIcon={
                <Icon icon={arrowIosDownwardFill} width={20} height={20} />
              }
              sx={{
                ...(controlled !== item.value && {
                  backgroundColor: "default.main",
                }),
              }}
            >
              <Typography
                variant="subtitle1"
                color={
                  controlled === item.value ? "primary.main" : "text.primary"
                }
                sx={{ width: "33%", flexShrink: 0 }}
              >
                {item.heading}
              </Typography>
              <Typography>{item.subHeading}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingLeft: 5, paddingRight: 2 }}>
              {item.details?.map(({ caption, list }, index) => (
                <>
                  <Stack>
                    <Typography variant="body1" color="text.secondary">
                      {caption}
                    </Typography>
                    <Stack ml={2} mb={2} mt={2}>
                      {list &&
                        list.map((listItem) => {
                          return (
                            <>
                              <Typography
                                variant="body1"
                                color="text.secondary"
                                fontWeight={700}
                              >
                                {listItem}
                              </Typography>
                            </>
                          );
                        })}
                    </Stack>
                  </Stack>
                </>
              ))}
              {/* <Typography color="text.secondary" variant="body1">
                {item.details}
              </Typography> */}
              {/* {item.steps && (
                <Box sx={{ marginTop: 2 }}>
                  {item.steps.map((step: string) => (
                    <Typography
                      variant="body1"
                      fontWeight={700}
                      color="text.secondary"
                    >
                      {step}
                    </Typography>
                  ))}
                </Box>
              )} */}
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </RootStyle>
  );
}
