import Slider from "react-slick";
import { useRef } from "react";
import { Icon } from "@iconify/react";
import { Link as RouterLink } from "react-router-dom";
import arrowForwardFill from "@iconify/icons-eva/arrow-forward-fill";
// material
import { alpha, useTheme, styled } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
// utils
import { CarouselControlsArrowsBasic2 } from "components/carousel";
import { Card } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  "&:before, &:after": {
    top: 0,
    left: 0,
    zIndex: 8,
    width: 48,
    content: "''",
    height: "100%",
    display: "none",
    position: "absolute",
    [theme.breakpoints.up(480)]: {
      display: "block",
    },
  },
  "&:after": {
    right: 0,
    left: "auto",
    transform: "scaleX(-1)",
  },
}));

// ----------------------------------------------------------------------

type CarouselItemProps = {
  title: string;
  description: string;
  icon: string;
};

function CarouselItem({ item }: { item: CarouselItemProps }) {
  const { icon, title } = item;

  return (
    <Card
      sx={{
        padding: 2,
        margin: 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Icon icon={icon} width={40} />
      <Typography variant="body2" color="text.secondary">
        {title}
      </Typography>
    </Card>
  );
}

interface CarouselCenterModeProps {
  id: number;
  item: {
    title: string;
    description: string;
    icon: string;
  };
}

export default function CarouselCenterMode({
  carousel,
}: {
  carousel: CarouselCenterModeProps[];
}) {
  const carouselRef = useRef<Slider | null>(null);
  const theme = useTheme();

  const settings = {
    slidesToShow: 3,
    centerMode: true,
    centerPadding: "60px",
    rtl: Boolean(theme.direction === "rtl"),
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, centerPadding: "0" },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <RootStyle>
      <Slider ref={carouselRef} {...settings}>
        {carousel.map((item) => (
          <CarouselItem key={item.id} item={item.item} />
        ))}
      </Slider>
      <CarouselControlsArrowsBasic2
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </RootStyle>
  );
}
