import { Icon } from "@iconify/react";
import { Button, Card, Link, Stack, Typography } from "@mui/material";

const WhatsappSection = () => {
  const Wlink = `https://api.whatsapp.com/send/?phone=558007999901&text=Vim+pelo+seu+site+e+gostaria+de+emitir+meu+certificado+digital.&type=phone_number&app_absent=0`;
  return (
    <Card
      component={Stack}
      spacing={2}
      sx={{ height: "100%", backgroundColor: "#C8FACD", padding: 3 }}
    >
      <Typography variant="h4" color="rgb(0, 82, 73)">
        Precisa de ajuda?
      </Typography>
      <Typography variant="body2" color="rgb(0, 82, 73)">
        Nos chame no Whatsapp! <br />
        Estamos de prontidão para te atender!!
      </Typography>
      <Button
        component={Link}
        href={Wlink}
        target="_blank"
        variant="contained"
        size="large"
        color="success"
        sx={{
          backgroundColor: "rgb(0, 171, 85)",
          boxShadow: "none",
          transition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          "&:hover": {
            backgroundColor: "rgb(0, 92, 73)",
          },
        }}
        endIcon={<Icon icon="mdi:whatsapp" color="rgb(255, 255, 255)" />}
      >
        <Typography
          className="whatsapp-gtg"
          variant="button"
          color="rgb(255, 255, 255)"
        >
          Enviar mensagem
        </Typography>
      </Button>
    </Card>
  );
};

export default WhatsappSection;
