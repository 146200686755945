import { Icon } from "@iconify/react";
import roundArrowRightAlt from "@iconify/icons-ic/round-arrow-right-alt";
// material
import { alpha, useTheme, styled } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Link,
  Paper,
  Rating,
  Container,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
//
import { MenuHidden } from "../components/@material-extend";
import {
  varFadeInUp,
  varFadeInLeft,
  MotionInView,
} from "../components/animate";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(10, 0),
  backgroundSize: "cover",
  backgroundImage: `linear-gradient(to right, ${alpha(
    theme.palette.grey[900],
    0.8
  )} , ${alpha(
    theme.palette.grey[900],
    1
  )}), url(/static/page-images/about_testimonials.svg)`,
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    padding: 0,
    height: 840,
    overflow: "hidden",
  },
}));

// ----------------------------------------------------------------------

function TestimonialLink() {
  return (
    <Link
      href="https://www.google.com/search?q=password+certficado+digital&sxsrf=APwXEddRnF0Z_aPJbp_KhL4mmLYurOtmQg%3A1684415423225&ei=vyNmZOeyDaqu5OUP8fSG8Ak&ved=0ahUKEwinjtK3-P7-AhUqF7kGHXG6AZ4Q4dUDCA8&uact=5&oq=password+certificado+digital&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIHCCMQigUQJzILCC4QgAQQxwEQrwEyBggAEBYQHjIGCAAQFhAeMgIIJjIZCC4QgAQQxwEQrwEQlwUQ3AQQ3gQQ4AQYAToOCC4QgAQQxwEQrwEQsAM6CQgAEAcQHhCwAzoJCAAQBRAeELADOgsIABAIEB4QDxCwA0oECEEYAVDpBVjmDmCKEWgBcAB4AIABlgGIAfsIkgEDMC44mAEAoAEByAEFwAEB2gEGCAEQARgU&sclient=gws-wiz-serp#lrd=0x93a781b215500e39:0x5c469d97377c272f,1,,,,"
      target="_blank"
      variant="subtitle2"
      sx={{ display: "flex", alignItems: "center" }}
    >
      Ver todas as avaliações
      <Box
        component={Icon}
        icon={roundArrowRightAlt}
        sx={{ ml: 1, width: 20, height: 20 }}
      />
    </Link>
  );
}

type TestimonialCardProps = {
  testimonial: {
    name: string;
    rating: number;
    content: string;
    dateCreate: Date | string;
  };
};

function TestimonialCard({ testimonial }: TestimonialCardProps) {
  const { name, rating, dateCreate, content } = testimonial;
  return (
    <Paper
      sx={{
        mt: 3,
        p: 3,
        color: "common.white",
        backdropFilter: "blur(4px)",
        WebkitBackdropFilter: "blur(4px)", // Fix on Mobile
        bgcolor: (theme) => alpha(theme.palette.common.white, 0.04),
      }}
    >
      <Typography variant="subtitle1" gutterBottom>
        {name}
      </Typography>
      {/* <Typography gutterBottom component="p" variant="caption" sx={{ color: 'grey.500' }}>
        {dateCreate}
      </Typography> */}
      <Rating value={rating} readOnly size="small" />
      <Typography variant="body2" sx={{ mt: 1.5 }}>
        {content}
      </Typography>
    </Paper>
  );
}

type TestimonialProps = {
  TESTIMONIALS: {
    name: string;
    rating: number;
    content: string;
    dateCreate: Date | string;
  }[];
};

export default function AboutTestimonials({ TESTIMONIALS }: TestimonialProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <RootStyle>
      <Container maxWidth="lg" sx={{ position: "relative", height: "100%" }}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent={{ xs: "center", md: "space-between" }}
          sx={{ height: "100%" }}
        >
          <Grid item xs={10} md={4}>
            <Box sx={{ maxWidth: { md: 360 } }}>
              <MotionInView variants={varFadeInUp}>
                <Typography
                  component="p"
                  variant="overline"
                  sx={{ mb: 2, color: "text.secondary" }}
                >
                  Avaliações
                </Typography>
              </MotionInView>

              <MotionInView variants={varFadeInUp}>
                <Typography variant="h2" sx={{ mb: 3, color: "common.white" }}>
                  Quem ama <br />
                  nosso trabalho
                </Typography>
              </MotionInView>

              {/* <MotionInView variants={varFadeInUp}>
                <Typography sx={{ color: "common.white" }}>
                  Our goal is to create a product and service that you’re
                  satisfied with and use it every day. This is why we’re
                  constantly working on our services to make it better every day
                  and really listen to what our users has to say.
                </Typography>
              </MotionInView> */}

              <MenuHidden width="mdUp">
                <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                  <MotionInView variants={varFadeInUp}>
                    <TestimonialLink />
                  </MotionInView>
                </Box>
              </MenuHidden>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={7}
            lg={6}
            sx={{
              right: { md: 24 },
              position: { md: "absolute" },
            }}
          >
            <Grid container spacing={isDesktop ? 3 : 0} alignItems="center">
              <Grid item xs={12} md={6}>
                {TESTIMONIALS.slice(0, 3).map((testimonial) => (
                  <MotionInView key={testimonial.name} variants={varFadeInUp}>
                    <TestimonialCard testimonial={testimonial} />
                  </MotionInView>
                ))}
              </Grid>

              <Grid item xs={12} md={6}>
                {TESTIMONIALS.slice(3, 6).map((testimonial) => (
                  <MotionInView key={testimonial.name} variants={varFadeInUp}>
                    <TestimonialCard testimonial={testimonial} />
                  </MotionInView>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <MenuHidden width="mdDown">
          <Box sx={{ bottom: 60, position: "absolute" }}>
            <MotionInView variants={varFadeInLeft}>
              <TestimonialLink />
            </MotionInView>
          </Box>
        </MenuHidden>
      </Container>
    </RootStyle>
  );
}
