import { Box, Button, Card, Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectConclusion } from "services/store/conclusionSlice";
import { fCurrency, fCurrencyUSD } from "utils/formatNumber";
import {setDataLayer} from "./conclusionFunction";



const Conclusion: React.FC = () => {
  const conclusionData = useSelector(selectConclusion);
  const navigate = useNavigate()
  const name =
    conclusionData.name.split(" ")[0].concat("+") +
    conclusionData.name.split(" ")[1];
  const protocol = conclusionData.protocol;
  const wLink = `https://api.whatsapp.com/send/?phone=558007999901&text=%5Becommerce%5D+Ol%C3%A1+sou+${name}%2C+meu+protocolo+%C3%A9%3A+${protocol}+gostaria+de+realizar+meu+agendamento&type=phone_number&app_absent=0`;

console.log(conclusionData);


  useEffect(() => {
    setDataLayer(fCurrencyUSD(conclusionData.value), conclusionData.protocol);
  }, []);



  return (
// Exemplo de função JavaScript
    <Container maxWidth="md">
      <Stack
        width="100%"
        height="100vh"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Card sx={{ width: "100%" }}>
          <Box
            sx={{
              padding: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              gap: 4,
            }}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
                Compra realizada com sucesso!
              </Typography>
              <Typography variant="body1">
                Logo abaixo estão os links para realização do pagamento e do
                agendamento. <br />
                Os próximos passos serão solicitados via email. <br />
                <strong>Obs:</strong> A emissão do certificado só é possível
                após a confirmação do Pagamento.
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h4">Pagamento</Typography>
              <Typography>
                Realize seu pagamento{" "}
                <a
                  href={conclusionData.invoiceLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  clicando aqui.
                </a>
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h4">Agendamento</Typography>
              <Typography variant="body1">
                Já estamos enviando uma mensagem a você! Caso isso não aconteça,
                realize seu agendamento{" "}
                <a href={wLink} target="_blank" rel="noreferrer">
                  clicando aqui.
                </a>
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h4">Informações da Compra</Typography>
              <Stack direction="column" spacing={1}>
                {conclusionData.protocol && (
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body1">Protocolo:</Typography>
                    <Typography variant="body2">
                      {conclusionData.protocol}
                    </Typography>
                  </Stack>
                )}
                <Stack direction="row" spacing={1}>
                  <Typography variant="body1">CPF:</Typography>
                  <Typography variant="body2">{conclusionData.cpf}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body1">Nome:</Typography>
                  <Typography variant="body2">{conclusionData.name}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body1">E-Mail:</Typography>
                  <Typography variant="body2">{conclusionData.email}</Typography>
                </Stack>      
                <Stack direction="row" spacing={1}>
                  <Typography variant="body1">Valor:</Typography>
                  <Typography variant="body2">{fCurrency(conclusionData.value)}</Typography>
                </Stack>                              
              </Stack>
            </Stack>
          </Box>
        </Card>
        <Button onClick={() => navigate('/')} size="large" sx={{ alignSelf: "start" }}>Voltar para a tela inicial</Button>
      </Stack>
    </Container>
  );
};

export default Conclusion;
