type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm&hl=pt

declare const window: WindowWithDataLayer;

export const setDataLayer = (value: string, transactionID : string) => {

  window.dataLayer.push({
    event: 'purchase',
    value, 
    transactionID,  
  }); 
}

