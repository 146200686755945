import { createSlice } from "@reduxjs/toolkit";

export interface SliceConclusionProps {
  name: string;
  cpf: string;
  email: string;
  protocol: string;
  value : number;
  invoiceLink: string;
}

export interface ReducerConclusionProps {
  type: string;
  payload: SliceConclusionProps;
}

export const slice = createSlice({
  name: "conclusion",
  initialState: {
    name: "",
    cpf: "",
    email: "",
    protocol: "",
    value : 0,
    invoiceLink: ""
  },
  reducers: {
    makeConclusion(state, { payload }: ReducerConclusionProps) {
      state = payload;
      return state;
    },
  },
});

export const { makeConclusion } = slice.actions;

export const selectConclusion = (state: any) =>
  state.conclusion as SliceConclusionProps;

export default slice.reducer;
