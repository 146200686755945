import * as React from "react";
import { styled } from "@mui/material/styles";
import { stepConnectorClasses } from "@mui/material/StepConnector";
import {
  StepLabel,
  StepConnector,
  Step,
  Stack,
  Typography,
  Stepper as MuiStepper,
} from "@mui/material";
import { StepperProps } from "../model";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#fda92d;",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#fda92d;",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const Stepper: React.FC<StepperProps> = ({ steps, currentStep }) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);

  React.useEffect(() => {
    steps.forEach((step, index) => {
      if (step === currentStep) {
        setCurrentStepIndex(index);
      }
    });
  });

  return (
    <Stack spacing={4} sx={{ display: "inline-block" }}>
      <MuiStepper
        alternativeLabel
        activeStep={currentStepIndex}
        connector={<QontoConnector />}
        sx={{ width: "auto", justifyContent: "flex-start" }}
      >
        {steps.map((label) => (
          <Step
            key={label}
            sx={{
              width: "auto",
            }}
          >
            <Typography noWrap textOverflow="ellipsis" component={StepLabel}>
              {label}
            </Typography>
          </Step>
        ))}
      </MuiStepper>
    </Stack>
  );
};

export default Stepper;
