import { useState } from "react";
import {
  Container,
  useMediaQuery,
  useTheme,
  Stack,
  Typography,
  Card,
  Box,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import CarouselCenterMode from "./carousel";
import AccordionComponent from "./Accordion";

const currentTypeListData = [
  {
    id: "eCpf",
    text: "É a versão eletrônica do CPF que pode ser usado para acessar portais de serviços eletrônicos, assinar diversos tipos de documentos e muito mais, com total segurança e validade jurídica.",
  },
  {
    id: "eCnpj",
    text: "É a versão eletrônica do CNPJ, que pode ser usado para acessar portais de serviços eletrônicos, assinar diversos tipos de documentos, emitir notas fiscais eletrônicas e muito mais, com total segurança e validade jurídica.",
  },
  {
    id: "ePj",
    text: "É indicado para empresas que necessitam assinar digitalmente documentos fiscais eletrônicos, como notas fiscais de produtos e conhecimentos de transporte.",
  },
];

const currentEmissionListData = [
  {
    id: "videoconferencia",
    value: "panel1",
    heading: "Videoconferência",
    detail:
      "A emissão por videoconferência permite que você adquira o seu Certificado Digital de forma prática, segura e sem precisar sair de casa.",
    steps: [
      "1. Compra do Certificado",
      "2. Validação dos documentos (caso necessário)",
      "3. Agendamento da videoconferência",
      "4. Videoconferência",
      "5. Emissão",
    ],
    image: "https://media-public.canva.com/Xc9F4/MAEU9UXc9F4/2/tl.png",
  },
  {
    id: "presencial",
    value: "panel2",
    heading: "Presencial",
    detail:
      "A emissão presencial permite que você escolha o local mais próximo da sua casa para emitir o seu Certificado, tendo todo o nosso apoio no momento da emissão.",
    steps: [
      "1. Compra do Certificado",
      "2. Agendamento do Atendimento",
      "3. Atendimento presencial para emissão",
      "4. Validação dos documentos (caso necessário)",
    ],
    image: "https://media-public.canva.com/Xc9F4/MAEU9UXc9F4/2/tl.png",
  },
  {
    id: "online",
    value: "panel3",
    heading: "On-line",
    detail:
      "A emissão on-line permite que você emita um Certificado Digital de Pessoa Jurídica a partir do seu certificado de Pessoa Física. Todo o processo de emissão pode ser feito de forma prática, segura e sem precisar sair de casa.",
    steps: [
      "1. Escolha do certificado",
      "2. Validação do certificado válido já existente",
      "3. Compra do certificado escolhido",
      "4. Emissão",
    ],
    image: "https://media-public.canva.com/Xc9F4/MAEU9UXc9F4/2/tl.png",
  },
  {
    id: "renovacao",
    value: "panel4",
    heading: "Renovação",
    detail:
      "A Renovação permite que você emita um novo certificado digital a partir de outro certificado válido já existente. Todo o processo de validação pode ser feito pelo cliente no momento da compra.",
    steps: [
      "1. Escolha do certificado",
      "2. Validação do certificado válido já existente",
      "3. Compra do certificado escolhido",
      "4. Emissão",
    ],
    image: "https://media-public.canva.com/Xc9F4/MAEU9UXc9F4/2/tl.png",
  },
];

const Security = () => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Stack
      component={Stack}
      direction={{ xs: "column-reverse", sm: "row" }}
      spacing={8}
      alignItems="center"
      sx={{ padding: 3, mt: 10 }}
    >
      <Stack
        direction="column"
        spacing={3}
        alignItems="start"
        justifyContent="center"
      >
        <Typography variant="h5" color="primary.main">
          SEGURANÇA
        </Typography>
        <Typography variant="h4" color="text.primary">
          Informações digitais seguras e confiáveis
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Somos a AR Password Certificação, emissora de Certificados Digitais.
          Queremos que se sinta seguro e estamos dedicados em proporcionar a
          melhor experiência e segurança aos nossos clientes.
        </Typography>
        <Stack
          direction="row"
          spacing={3}
          justifyContent="start"
          sx={{ width: "100%" }}
        >
          <Card
            component={Stack}
            direction="column"
            spacing={1}
            alignItems="start"
            sx={{ padding: 3, width: "100%", maxWidth: "270px" }}
          >
            <Typography variant="h6" noWrap color="primary.main">
              + de 20.000
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Certificados Digitais emitidos
            </Typography>
          </Card>
          <Card
            component={Stack}
            direction="column"
            spacing={1}
            alignItems="start"
            sx={{ padding: 3, width: "100%", maxWidth: "270px" }}
          >
            <Typography variant="h6" noWrap color="primary.main">
              + de 1.500
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Clientes ativos mensalmente
            </Typography>
          </Card>
        </Stack>
      </Stack>
      {upSm && (
        <Box sx={{ maxWidth: "300px", minWidth: "200px" }}>
          <img
            src="
        https://media-public.canva.com/tbBrc/MAEpdRtbBrc/1/tl.png"
            alt=""
            style={{ borderRadius: "20px" }}
          />
        </Box>
      )}
    </Stack>
  );
};

const Facility = () => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <>
      <Grid
        container
        spacing={3}
        direction="row-reverse"
        alignItems="center"
        justifyContent="start"
        sx={{ width: "100%", paddingRight: 3, paddingBottom: 3 }}
      >
        <Grid item xs={12} md={8}>
          <Stack
            direction="column"
            spacing={3}
            alignItems="start"
            justifyContent="start"
            flexWrap="wrap"
          >
            <Typography variant="h5" color="primary.main">
              FACILIDADE
            </Typography>
            <Typography variant="h4" color="text.primary">
              Usos e aplicações do Certificado Digital
            </Typography>
            <Typography variant="body1" color="text.secondary">
              O Certificado Digital ICP-Brasil funciona como uma identidade
              virtual que permite a identificação segura do autor de uma
              mensagem ou transação feita em meios eletrônicos.
            </Typography>
          </Stack>
          <CarouselCenterMode />
        </Grid>
        {upSm && (
          <Grid item xs={12} md={4}>
            <Box sx={{ maxWidth: "400px" }}>
              <img
                src="https://media-public.canva.com/i5ink/MAEV7Ti5ink/1/tl.png"
                alt="imagem"
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const Types = () => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [currentType, setCurrentType] = useState("eCpf");
  return (
    <>
      <Grid
        component={Grid}
        container
        spacing={3}
        direction="row"
        alignItems="center"
        justifyContent="start"
        sx={{ width: "100%", paddingRight: 3, paddingBottom: 3 }}
      >
        <Grid item xs={12} md={8}>
          <Stack
            direction="column"
            spacing={3}
            alignItems="start"
            justifyContent="start"
            flexWrap="wrap"
          >
            <Typography variant="h4" color="text.primary">
              Conheça os tipos de Certificados Digitais
            </Typography>
            <Tabs
              onChange={(event, value) => setCurrentType(value)}
              value={currentType}
              sx={{ margin: 10 }}
            >
              <Tab value="eCpf" label="e-CPF" />
              <Tab value="eCnpj" label="e-CNPJ" />
              <Tab value="ePj" label="PJ NF-e" />
            </Tabs>
            {currentTypeListData
              .filter((el) => el.id === currentType)
              .map((el, index) => (
                <Typography key={index} variant="body1" color="text.secondary">
                  {el.text}
                </Typography>
              ))}
          </Stack>
        </Grid>
        {upSm && (
          <Grid item xs={12} md={4}>
            <Box sx={{ maxWidth: "300px" }}>
              <img src="https://media-public.canva.com/bJIXI/MAFGbJbJIXI/1/tl.png" />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const Emission = () => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Grid
        component={Grid}
        container
        spacing={3}
        direction="row"
        alignItems="center"
        justifyContent="start"
        sx={{ width: "100%", paddingRight: 3, paddingBottom: 3 }}
      >
        <Grid item xs={12}>
          <Stack
            direction="column"
            spacing={3}
            alignItems="center"
            justifyContent="start"
            flexWrap="wrap"
            sx={{ width: "100%" }}
          >
            <Typography variant="h4" color="text.primary">
              Entenda a diferença entre os tipos de emissão
            </Typography>
            <Stack direction="row">
              <AccordionComponent items={currentEmissionListData} />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

const Informations = () => {
  return (
    <Container maxWidth="lg">
      <Stack spacing={10}>
        <Security />
        <Facility />
        <Types />
        <Emission />
      </Stack>
    </Container>
  );
};

export default Informations;
