import AboutTestimonials from "adapted-components/AboutTestimonials";

const TESTIMONIALS = [
  {
    name: "Cleia Truilho Ricieri",
    rating: 5,
    dateCreate: "Um ano atrás",
    content: `Excelente atendimento.  Equipe incrível, educada e pronto para auxiliar a minha empresa em tudo que preciso`,
  },
  {
    name: "Gabriel Pellizari",
    rating: 5,
    dateCreate: "2 anos atrás",
    content: `excelente atendimento, equipe muito comprometida e profissional 🤝`,
  },
  {
    name: "klauberth rondon",
    rating: 5,
    dateCreate: "3 anos atrás",
    content: `Atendimento mais que perfeito, tudo que eu precisava encontrei aqui,pessoa qualificada e com disposição e educação no atendimento,parabéns equipe password muito grata pelo excelente atendimento.`,
  },
  {
    name: "Roberto Bruno Pedrosa",
    rating: 5,
    dateCreate: "3 anos atrás",
    content: `Super indico , muito bem atendido e ótimo trabalho executado ......`,
  },
  {
    name: "Arthur Vasconcelos",
    rating: 5,
    dateCreate: "uma semana atrás",
    content: `Precisei comprar em cima da hora e fui muito bem atendido pelo Felipe. Rapidamente ele me encaixou para a video conferencia e no mesmo dia eu ja consegui ativar meu token. Foi tão rapido que nem vi que ja estava instalando o certificado e ainda nao tinha feito o pagamento..rs Recomendo!`,
  },
  {
    name: "Caroline Pereira",
    rating: 5,
    dateCreate: "3 anos atrás",
    content: `Ótimo atendimento, ambiente bem confortável não fiquei esperando muito, consegui obter meu certificado de forma prática.`,
  },
];

const Avaliations = () => {
  return <AboutTestimonials {...{ TESTIMONIALS }} />;
};

export default Avaliations;
