// StepComponents
import {
  Box,
  Card,
  Typography,
  Stack,
  Container,
  Button,
  Divider,
  TextField,
  Grid,
  InputAdornment,
} from "@mui/material";
import { RootStyle } from "./styles";

import LoadingScreen from "../../../components/LoadingScreen";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";


import Cart from "../components/cart";
import PreventConsult from "../components/preventConsult";
import Stepper from "../components/stepper";
import Identification from "../components/identification";
import PaymentMethods from "../components/paymentMethods";

import { fCurrency } from "../../../utils/formatNumber";
import { CheckoutViewProps } from "../model";

const View: React.FC<CheckoutViewProps> = ({
  steps,
  checkout,
  currentStep,
  setCurrentStep,
  saveClient,
  isCnpj,
  handleNextStep,
  handleBackStep,
  makeConsult,
  setMakeConsult,
  isCart,
  isConsult,
  isIdentification,
  isPayment,
  isConclusion,
  preventConsultResponse,
  preventConsult,
  isLoading,
  stepsData,
  handleSubmitPaymentMethod,
  makeSubmitPaymentMethod,
  paymentOptions,
  installmentsOptions,
  setMakeSubmitPaymentMethod,
  previewData,
}) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingScreen />
      </Box>
    );
  }

  return (
    <RootStyle>
      <Container
        id="general-container"
        sx={{
          maxWidth: "xl",
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <HeaderBreadcrumbs
          heading="Checkout"
          links={[
            { name: "Home", href: '/' },
            { name: "Adquirir Certificado" },
            { name: "Checkout" },
          ]}
          sx={{ margin: 0, padding: 0 }}
        />
        {window.innerWidth > 600 && <Stepper {...{ steps, currentStep }} />}
        <Grid
          id="geral"
          container
          direction={{ xs: "column", md: "row" }}
          spacing={3}
          alignItems={{ md: "flex-start" }}
          sx={{ flexWrap: "noWrap" }}
        >
          <Grid item xs={12} md={isIdentification ? 12 : 8}>
            <Card
              id="main-card"
              sx={{
                width: "auto",
                height: "auto",
                padding: isCart ? `${8}px ${0}px` : `${24}px ${24}px ${24}px`,
                minWidth: 300,
              }}
            >
              {isCart && <Cart {...{ checkout }} />}
              {isConsult && (
                <PreventConsult
                  {...{
                    setCurrentStep,
                    makeConsult,
                    setMakeConsult,
                    checkout,
                    preventConsult,
                    isCnpj,
                    initialValues: stepsData.preventConsult,
                  }}
                />
              )}
              {isIdentification && (
                <Identification
                  {...{
                    checkout,
                    setCurrentStep,
                    preventConsultResponse,
                    saveClient,
                    isCnpj,
                    handleBackStep,
                    initialValues: stepsData.identification,
                  }}
                />
              )}
              {isPayment && (
                <PaymentMethods
                  {...{
                    initialValues: stepsData.paymentMethods,
                    handleSubmitPaymentMethod,
                    makeSubmitPaymentMethod,
                    paymentOptions,
                    installmentsOptions,
                    setMakeSubmitPaymentMethod,
                    previewData,
                  }}
                />
              )}
            </Card>
          </Grid>
          {!isIdentification && (
            <Grid
              id="price-card"
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              md={4}
              spacing={2}
              sx={{ maxHeight: 400 }}
            >
              <Grid item xs={12}>
                <Card
                  sx={{
                    padding: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    height: "100%",
                    minWidth: 300,
                  }}
                >
                  <Typography fontWeight={700} fontSize="1.125rem" noWrap>
                    Resumo da Compra
                  </Typography>
                  <Stack sx={{ gap: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        color=" rgb(99, 115, 129)"
                        fontSize="0.875rem"
                      >
                        Sub Total
                      </Typography>
                      <Typography fontWeight={600} fontSize="0.875rem">
                        {fCurrency(checkout.valorUnitario)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        fontWeight={400}
                        color=" rgb(99, 115, 129)"
                        fontSize="0.875rem"
                      >
                        Desconto
                      </Typography>
                      <Typography fontWeight={600} fontSize="0.875rem">
                        -
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography fontWeight={600} fontSize="1rem">
                        Total
                      </Typography>
                      <Typography fontWeight={600} fontSize="1rem">
                        {fCurrency(checkout.valorUnitario)}
                      </Typography>
                    </Box>
                  </Stack>

                  {/* TextField 
                    label="Cupom de Desconto"
                    sx={{ minWidth: 240 }}
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button>Aplicar</Button>
                        </InputAdornment>
                      ),
                    }}
                      */}
                 
                </Card>
              </Grid>
              <Grid
                xs={12}
                sx={{ minWidth: "300px" }}
                item
                container
                spacing={2}
                justifyContent={{ xs: "space-between" }}
                alignItems={{ xs: "center", md: "initial" }}
                direction={{ xs: "row-reverse", md: "column" }}
              >
                <Grid item xs={6} md={12}>
                  <Button
                    onClick={handleNextStep}
                    size="large"
                    variant="contained"
                    fullWidth
                    component={Typography}
                    sx={{ textTransform: "none", width: "100%" }}
                  >
                     Prosseguir
                  </Button>
                </Grid>
                <Grid item xs={6} md={12}>
                  {!isConclusion && (
                    <Button
                      sx={{ width: "100%" }}
                      onClick={handleBackStep}
                      size="large"
                      variant="outlined"
                      fullWidth
                      component={Typography}
                    >
                      Voltar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </RootStyle>
  );
};

export default View;
