import { Grid, Typography } from "@mui/material";
import { CartProps } from "../model";

const Cart: React.FC<CartProps> = ({ checkout }) => {
  const { emission, product, model, media, validity } = checkout;

  return (
    <Grid
      padding={3}
      spacing={{ xs: 4, sm: 2, md: 1 }}
      direction={{ sm: "row" }}
      alignItems="start"
      justifyContent="start"
      container
      minHeight={{ xs: "none", md: 450 }}
    >
      <Grid
        xs={6}
        sm={4}
        md={2}
        item
        container
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body1" fontWeight={700} fontSize="1rem">
          Produto
        </Typography>
        <Typography variant="body1">{product}</Typography>
      </Grid>
      <Grid
        xs={6}
        sm={4}
        md={2}
        item
        container
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body1" fontWeight={700} fontSize="1rem">
          Modelo
        </Typography>
        <Typography variant="body1">{model}</Typography>
      </Grid>
      <Grid
        xs={6}
        sm={4}
        md={2}
        item
        container
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body1" fontWeight={700} fontSize="1rem">
          Mídia
        </Typography>
        <Typography variant="body1">{media}</Typography>
      </Grid>
      <Grid
        xs={6}
        sm={4}
        md={2}
        item
        container
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body1" fontWeight={700} fontSize="1rem">
          Validade
        </Typography>
        <Typography variant="body1">{validity}</Typography>
      </Grid>
      <Grid
        xs={6}
        sm={4}
        md={3}
        item
        container
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body1" fontWeight={700} fontSize="1rem">
          Emissão
        </Typography>
        <Typography variant="body1">{emission}</Typography>
      </Grid>
    </Grid>
  );
};

export default Cart;
