import { Outlet } from "react-router-dom";
// material
import { Stack } from "@material-ui/core";
// components
//
import MainFooter from "./MainFooter";
import MainNavbar from "./MainNavbar";
import { useLocation } from "react-router";
import { Box, IconButton, Link } from "@mui/material";
import { Icon } from "@iconify/react";
import CookieConsent, { Cookies } from "react-cookie-consent";

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isCheckout = pathname === "/checkout";
  const Wlink = `https://api.whatsapp.com/send/?phone=558007999901&text=Vim+pelo+seu+site+e+gostaria+de+emitir+meu+certificado+digital.&type=phone_number&app_absent=0`;

  return (
    <Stack direction="column">
      <MainNavbar />

      <div style={{ minHeight: "100vh" }}>
        <Outlet />
      </div>
      <Box sx={{ position: "fixed", bottom: 20, right: 20 }}>
        <IconButton
          className="whatsapp-gtg"
          component={Link}
          href={Wlink}
          target="_blank"
        >
          <Icon className="whatsapp-gtg" icon="logos:whatsapp-icon" width="60" height="60" />
        </IconButton>
      </Box>
      
      {!isCheckout && (
        <div id="footer">
          <MainFooter />

        </div>
      )}
    </Stack>
    
  );
}
