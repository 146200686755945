import { useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";
import { HomeViewProps } from "../model";
import { RootStyle } from "./styles";
import LoadingScreen from "../../../components/LoadingScreen";
import Informations from "../components/informations";
import { AboutHero } from "../../../adapted-components";
import Form from "../components/Form";
import WhatsappSection from "../components/WhatsappSection";
import ProductList from "../components/ProductList";
import Avaliations from "../components/Avaliations";
import CookieConsent, { Cookies } from "react-cookie-consent";

const View: React.FC<HomeViewProps> = ({
  a1MediaOption,
  a3MediaOptions,
  defaultValidities,
  safeIdValidities,
  handleCheckout,
  isLoading,
}) => {
  if (isLoading) {
    window.scrollTo({ top: -88 });
    return (
      <RootStyle>
        <Box
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingScreen />
        </Box>
      </RootStyle>
    );
  }

  return (
    <>
      <RootStyle title="Password | Certificado digital">
        <Container>
          <Box sx={{ mt: 5 }}>
            <ProductList {...{ handleCheckout }} />
          </Box>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              mt: 10,
              mb: 15,
            }}
          >
            <Grid item xs={12} md={9}>
              <Form
                {...{
                  a1MediaOption,
                  a3MediaOptions,
                  defaultValidities,
                  safeIdValidities,
                  handleCheckout,
                  isLoading,
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <WhatsappSection />
            </Grid>
          </Grid>
        </Container>
        <Avaliations />
        <Informations />
        <CookieConsent
          location="bottom"
          buttonText="Estou ciente"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "##FDA92D", fontSize: "13px" }}
          expires={150}
        >
          Este site utiliza cookies para melhorar a experiência do usuário.{" "}
          <span style={{ fontSize: "10px" }}><a href="https://passwordcertificacao.com.br/privacy">Política de Privacidade</a></span>
          
        </CookieConsent>
      </RootStyle>
    </>
  );
};

export default View;
