export const PATH_PAGE: any = {
  home: "/home",
  checkout: "/checkout",
  declaration: "/declaracao",
  privacy: "/privacy",
  selectProduct: "/escolher-produto",
  products: "/produtos",
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD: any = {
  ola: "",
};
export const PATH_ADMIN: any = {
  ola: "",
};
