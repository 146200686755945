import { Icon } from "@iconify/react";
import { useState } from "react";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { styled } from "@material-ui/core/styles";
import {
  Box,
  Container,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
// utils
// components
import Page from "../../../components/Page";
//
import Block from "components/Block";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  // paddingTop: theme.spacing(11),
  // paddingBottom: theme.spacing(15)
}));

// ----------------------------------------------------------------------

export default function AccordionComponent({ items }: any) {
  const [controlled, setControlled] = useState<string | false>(false);

  const handleChangeControlled =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setControlled(isExpanded ? panel : false);
    };

  return (
    <RootStyle>
      <Container>
        <Block>
          {items.map((item: any, index: number) => (
            <Accordion
              key={index}
              expanded={controlled === item.value}
              onChange={handleChangeControlled(item.value)}
            >
              <AccordionSummary
                expandIcon={
                  <Icon icon={arrowIosDownwardFill} width={20} height={20} />
                }
                sx={{
                  ...(controlled !== item.value && {
                    backgroundColor: "grey.100",
                  }),
                }}
              >
                <Typography
                  variant="h5"
                  color={
                    controlled === item.value ? "primary.main" : "text.primary"
                  }
                  sx={{ width: "33%", flexShrink: 0 }}
                >
                  {item.heading}
                </Typography>
                <Typography>{item.subHeading}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingLeft: 5, paddingRight: 2 }}>
                <Typography color="text.secondary">{item.detail}</Typography>
                <Box sx={{ marginTop: 2 }}>
                  {item.steps.map((step: string, index: number) => (
                    <Typography
                      key={index}
                      variant="body1"
                      fontWeight={700}
                      color="text.secondary"
                    >
                      {step}
                    </Typography>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Block>
      </Container>
    </RootStyle>
  );
}
