import {
  AboutHero,
  AboutWhat,
  AboutTeam,
  AccordionComponent,
} from "../../../adapted-components";
import { RootStyle } from "./styles";
import SimpleAccordion, {
  AccordionComponentProps,
} from "../../../adapted-components/SimpleAccordion";

const carouselList = [
  {
    id: 0,
    item: {
      title: "Segurança contra fraudes",
      icon: "material-symbols:security",
      description: "asdZXVZf",
    },
  },
  {
    id: 1,
    item: {
      title: "Validade jurídica",
      icon: "emojione-v1:pen-over-stamped-envelope",
      description: "asdf",
    },
  },
  {
    id: 2,
    item: {
      title: "Redução de custos",
      icon: "carbon:piggy-bank",
      description: "asdf",
    },
  },
  {
    id: 3,
    item: {
      title: "Sustentabilidade",
      icon: "game-icons:forest",
      description: "asdf",
    },
  },
  {
    id: 4,
    item: {
      title: "Economia de tempo",
      icon: "ic:outline-more-time",
      description: "asdf",
    },
  },
  {
    id: 5,
    item: {
      title: "Condidencialidade",
      icon: "codicon:gist-secret",
      description: "asdf",
    },
  },

];

const currentEmissionListData = [
  {
    id: "videoconferencia",
    value: "panel1",
    heading: "Videoconferência",
    detail:
      "A emissão por videoconferência permite que você adquira o seu Certificado Digital de forma prática, segura e sem precisar sair de casa.",
    steps: [
      "1. Compra do Certificado",
      "2. Validação dos documentos (caso necessário)",
      "3. Agendamento da videoconferência",
      "4. Videoconferência",
      "5. Emissão",
    ],
    image: "https://media-public.canva.com/Xc9F4/MAEU9UXc9F4/2/tl.png",
  },
  {
    id: "presencial",
    value: "panel2",
    heading: "Presencial",
    detail:
      "A emissão presencial permite que você escolha o local mais próximo da sua casa para emitir o seu Certificado, tendo todo o nosso apoio no momento da emissão.",
    steps: [
      "1. Compra do Certificado",
      "2. Agendamento do Atendimento",
      "3. Atendimento presencial para emissão",
      "4. Validação dos documentos (caso necessário)",
    ],
    image: "https://media-public.canva.com/Xc9F4/MAEU9UXc9F4/2/tl.png",
  },
  {
    id: "online",
    value: "panel3",
    heading: "On-line",
    detail:
      "A emissão on-line permite que você emita um Certificado Digital de Pessoa Jurídica a partir do seu certificado de Pessoa Física. Todo o processo de emissão pode ser feito de forma prática, segura e sem precisar sair de casa.",
    steps: [
      "1. Escolha do certificado",
      "2. Validação do certificado válido já existente",
      "3. Compra do certificado escolhido",
      "4. Emissão",
    ],
    image: "https://media-public.canva.com/Xc9F4/MAEU9UXc9F4/2/tl.png",
  },
  {
    id: "renovacao",
    value: "panel4",
    heading: "Renovação",
    detail:
      "A Renovação permite que você emita um novo certificado digital a partir de outro certificado válido já existente. Todo o processo de validação pode ser feito pelo cliente no momento da compra.",
    steps: [
      "1. Escolha do certificado",
      "2. Validação do certificado válido já existente",
      "3. Compra do certificado escolhido",
      "4. Emissão",
    ],
    image: "https://media-public.canva.com/Xc9F4/MAEU9UXc9F4/2/tl.png",
  },
];

const faqList: AccordionComponentProps[] = [
  {
    id: 1,
    value: "panel1",
    heading: "O que é Certificado Digital e-CPF?",
    details: [
      {
        caption:
          "O e-CPF é o documento eletrônico de identificação da pessoa física. Com o Certificado Digital e-CPF é possível o acesso a todos os serviços oferecidos pelo Governo Federal na internet, como o envio da Declaração do Imposto de Renda de PF (DIRPF), consulta e atualização de cadastro como contribuinte pessoa física, recuperação de informações sobre histórico de declarações e verificação da situação junto à Receita Federal, entre outros.",
        list: [
          "1. e-Social – Sistema de Escrituração Digital das Obrigações Fiscais, Previdenciárias e Trabalhistas.",
          "2. Conectividade Social ICP – Recolhimento FGTS.",
          "3. DCTF Web – Declarações de Débitos e Créditos Tributários Federais Previdenciários e de Outras Entidades e Fundos (mudou de DCTF para DCTF Web, pois passou a substituir a GFIP).",
          "4. DMED – Declaração de Serviços Médicos e de Saúde.",
          "5. SPED – Sistema Público de Escrituração Digital.",
          "6. EFD-Reinf – Escrituração Fiscal Digital de Retenções e Outras Informações Fiscais.",
          "7. Entre outros.",
        ],
      },
    ],
  },
  {
    id: 2,
    value: "panel2",
    heading: "O que é Certificado Digital?",
    details: [
      {
        caption:
          "O Certificado Digital é a identidade eletrônica da pessoa física ou jurídica em ambiente virtual. É o documento eletrônico que identifica e gera a assinatura digital do seu titular.",
      },
    ],
  },
  {
    id: 3,
    value: "panel3",
    heading: "Quais as diferenças entre os certificados A1 e A3?",
    details: [
      {
        caption: `A principal diferença entre os tipos A1 e A3 é o armazenamento do seu Certificado Digital.\n
      No caso do tipo A1, ele é emitido e armazenado no computador do titular, sendo protegido por senha.\n
      Já nos tipos A3, eles ficam armazenados em diferentes mídias, como cartão smartcard, token ou em nuvem.`,
      },
    ],
  },
  {
    id: 4,
    value: "panel4",
    heading: "Quais os documentos necessários para adquirir um e-CPF?",
    details: [
      {
        caption: `Os documentos aceitos para emissão do e-CPF são:`,
        list: [
          "1. Cédula de identidade (CI/RG/DNI)",
          "2. Carteira Nacional de Habilitação (CNH)",
          "3. Carteira de Identidade Profissional (OAB, CRC, CRM, CRO e CREA)",
          "4. Carteira de Identidade de Estrangeiro (CIE, RNE, CRNM)",
          "5. Passaporte",
        ],
      },
      {
        caption: `Os documentos opcionais são:`,
        list: ["PIS, PASEP, CAEPF, CEI e título de eleitor."],
      },
      {
        caption: 'Caso esses dados sejam informados na compra do certificado, a comprovação do documento deverá ser apresentada no dia agendado para o atendimento.',
        list: [
          `
           O comprovante de CPF será necessário quando esse dado não constar no documento de identificação.
          `,
          `
            Para a sua segurança, a AR Password reserva-se no direito de solicitar documentos adicionais, caso julgue necessário.
          `,
          `
            Documentos que possuem prazo de validade precisam estar vigentes (exceto CNH).
          `,
      ]
      }
    ],
  },
];

const View: React.FC = () => {
  return (
    <RootStyle title="e-CPF">
      <AboutWhat
        {...{
          image1: "static/page-images/e_cpf_about_what_1.svg",
          image2: "static/page-images/e_cpf_about_what_2.svg",
          title: "Certificado Digital para Pessoa Física",
          description:
            "O Certificado Digital para pessoa física é a versão eletrônica do CPF. Com ele é possível acessar portais de serviços eletrônicos, assinar documentos e muito mais. Tudo com total segurança e validade jurídica.",
          button: {
            href: "/home",
            label: "Comprar e-CPF",
          },
        }}
      />
      <div style={{ marginTop: 70 }}>
        <AboutTeam {...{ carouselList, title: 'Conheça os benefícios de utilizar o e-CPF', subtitle: 'FACILIDADE' }} />
      </div>

      <AccordionComponent items={currentEmissionListData} />

      <SimpleAccordion items={faqList} title="Perguntas frequentes" />
    </RootStyle>
  );
};

export default View;
