import { Icon } from "@iconify/react";
import googleFill from "@iconify/icons-eva/google-fill";
import facebookFill from "@iconify/icons-eva/facebook-fill";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link as ScrollLink } from "react-scroll";
// material
import { styled } from "@material-ui/core/styles";
import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  IconButton,
  Stack,
} from "@material-ui/core";
import { PATH_PAGE } from "routes/paths";
import { useLocation } from "react-router";
// routes
// ----------------------------------------------------------------------

const SOCIALS = [
  {
    name: "FaceBook",
    icon: facebookFill,
    link: "https://www.facebook.com/passwordcertificacaodigital",
  },
  {
    name: "Instagram",
    iconString: "mdi:instagram",
    link: "https://www.instagram.com/passwordcertificacaodigital/",
  },
  {
    name: "Google",
    icon: googleFill,
    link: "https://www.google.com/maps/place/Password+Certificado+Digital/@-11.8484277,-55.5097656,15z/data=!4m6!3m5!1s0x93a781b215500e39:0x5c469d97377c272f!8m2!3d-11.8484277!4d-55.5097656!16s%2Fg%2F11hyg0w_lt",
  },
];

interface LinkProps {
  headline: string;
  children: {
    name: string;
    href?: string;
  }[];
}

const LINKS: LinkProps[] = [
  {
    headline: "Sobre",
    children: [
      { name: "TIPO - AR" },
      { name: "SITUAÇÃO - Credenciada" },
      { name: "PROCESSO - 00100.004263/2019-88" },
    ],
  },
  {
    headline: "Contato",
    children: [
      {
        name: "TELEFONE - 0800 799 9901",
      },
      { name: "EMAIL - atendimento@passwordcertificacao.com.br" },
      {
        name: "ENDEREÇO - R. dos Cajueiros, 1832 - St. Comercial, Sinop - MT, 78550-162",
        href: "https://www.google.com/maps/place/Password+Certificado+Digital/@-11.8484277,-55.5097656,15z/data=!4m6!3m5!1s0x93a781b215500e39:0x5c469d97377c272f!8m2!3d-11.8484277!4d-55.5097656!16s%2Fg%2F11hyg0w_lt",
      },
      {
        name: "ATENDIMENTO PRESENCIAL - Segunda - Sexta / 8:00 - 17:00",
      },
    ],
  },
  {
    headline: "Produtos",
    children: [
      { name: "Safe-ID" },
      { name: "e-CPF" },
      { name: "e-CNPJ" },
      { name: "Mídias Físicas" },
    ],
  },
];

const RootStyle = styled("div")(({ theme }) => ({
  backgroundColor: "#222",
  boxSizing: "border-box",
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const { pathname } = useLocation();
  const isDeclaration = pathname === "/declaracao";
  const isPrivacy = pathname === "/privacy";

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 5, height: "100%" }}>
        <Grid
          container
          justifyContent={{ xs: "center", md: "space-between" }}
          alignItems="center"
          sx={{ textAlign: { xs: "center", md: "left" } }}
          spacing={3}
        >
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            sx={{ mb: 3 }}
          >
            <Grid item xs={6}>
              <ScrollLink to="move_top" spy smooth>
                <img src="/static/footer/logo-white.png" alt="Logo Password" />
              </ScrollLink>
            </Grid>
            <Grid item xs={6}>
              <Link href="https://www.gov.br/iti/pt-br" target="blank">
                <img src="/static/footer/iti-icp.png" alt="Logo ITI" />
              </Link>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Stack
              spacing={5}
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" color="white" variant="h6">
                      {headline}
                    </Typography>
                    {children.map((link) => {
                      if (link.href) {
                        return (
                          <Link
                            href={link.href}
                            target="_blank"
                            key={link.name}
                            color="white"
                            variant="body2"
                            component={Link}
                            sx={{ display: "block" }}
                          >
                            {link.name}
                          </Link>
                        );
                      }
                      return (
                        <Typography
                          color="white"
                          variant="body2"
                          key={link.name}
                        >
                          {link.name}
                        </Typography>
                      );
                    })}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Divider />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <img
              style={{ margin: "auto" }}
              src="/static/footer/payment.png"
              alt="Métodos de Pagamento"
            />
          </Grid>
        </Grid>

        {isDeclaration ? (
          <Typography
            textAlign="center"
            mt={5}
            fontSize={13}
            color="text.disabled"
          >
            Ler Declaração de Práticas de Negócio
          </Typography>
        ) : (
          <Link href={PATH_PAGE.declaration}>
            <Typography textAlign="center" mt={5} fontSize={13}>
              Ler Declaração de Práticas de Negócio
            </Typography>
          </Link>
        )}
        {isPrivacy ? (
          <Typography
            textAlign="center"
            mt={5}
            fontSize={13}
            color="text.disabled"
          >
            Política de Privacidade
          </Typography>
        ) : (
          <Link href={PATH_PAGE.privacy}>
            <Typography textAlign="center" mt={5} fontSize={13}>
             Política de Privacidade
            </Typography>
          </Link>
        )}          

        <Typography
          component="p"
          variant="body2"
          color="white"
          sx={{
            mt: 5,
            fontSize: 13,
            textAlign: { xs: "center", md: "center" },
          }}
        >
          © 2023. Todos os direitos reservados - Password Soluções em Tecnologia
        </Typography>
        <Stack
          spacing={1.5}
          direction="row"
          justifyContent="center"
          sx={{ mt: 2, mb: 5 }}
        >
          {SOCIALS.map((social) => (
            <IconButton
              component={Link}
              href={social.link}
              target="blank"
              key={social.name}
              color="primary"
              sx={{ p: 1 }}
            >
              <Icon
                icon={social.icon || social.iconString}
                width={16}
                height={16}
              />
            </IconButton>
          ))}
        </Stack>
      </Container>
      <Divider />
    </RootStyle>
  );
}
