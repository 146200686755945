import { createSlice } from "@reduxjs/toolkit";
import { CertificateProps } from "screens/home/model";

export interface ReducerCheckoutProps {
  type: string;
  payload: CertificateProps;
}

export const slice = createSlice({
  name: "checkout",
  initialState: {
    product: "",
    model: "",
    media: "",
    validity: "",
    emission: "",
    hashProduto: "",
    valorUnitario: 0
  },
  reducers: {
    makeCheckout(state, { payload }: ReducerCheckoutProps) {
      state = payload;
      return state;
    },
  },
});

export const { makeCheckout } = slice.actions;

export const selectCheckout = (state: any) =>
  state.checkout as CertificateProps;

export default slice.reducer;
