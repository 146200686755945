import { Icon } from "@iconify/react";
import roundArrowRightAlt from "@iconify/icons-ic/round-arrow-right-alt";
// material
import { alpha, useTheme, styled } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  Container,
  Typography,
  LinearProgress,
} from "@material-ui/core";
// utils
import { fPercent } from "utils/formatNumber";
import mockData from "utils/mock-data";
//
import { MenuHidden } from "components/@material-extend";
import { varFadeInUp, varFadeInRight, MotionInView } from "components/animate";
import { Link } from "@mui/material";

// ----------------------------------------------------------------------

const LABEL = ["Development", "Design", "Marketing"];

const MOCK_SKILLS = [...Array(3)].map((_, index) => ({
  label: LABEL[index],
  value: mockData.number.percent(index),
}));

const RootStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
}));

// ----------------------------------------------------------------------

type ProgressItemProps = {
  progress: {
    label: string;
    value: number;
  };
};

function ProgressItem({ progress }: ProgressItemProps) {
  const { label, value } = progress;
  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mb: 1.5, display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2">{label}&nbsp;-&nbsp;</Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {fPercent(value)}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          "& .MuiLinearProgress-bar": { bgcolor: "grey.700" },
          "&.MuiLinearProgress-determinate": { bgcolor: "divider" },
        }}
      />
    </Box>
  );
}

interface AboutWhatProps {
  image1: string;
  image2: string;
  title: string;
  description: string;
  button?: {
    label: string;
    href: string;
  };
}

export default function AboutWhat({
  image1,
  image2,
  title,
  description,
  button,
}: AboutWhatProps) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.48
  )}`;

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <MenuHidden width="mdDown">
            <Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 7 } }}>
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item xs={6}>
                  <MotionInView variants={varFadeInUp}>
                    <Box
                      component="img"
                      src={image1}
                      sx={{
                        borderRadius: 2,
                        boxShadow: shadow,
                      }}
                    />
                  </MotionInView>
                </Grid>
                <Grid item xs={6}>
                  <MotionInView variants={varFadeInUp}>
                    <Box
                      component="img"
                      src={image2}
                      sx={{ borderRadius: 2 }}
                    />
                  </MotionInView>
                </Grid>
              </Grid>
            </Grid>
          </MenuHidden>

          <Grid item xs={12} md={6} lg={5}>
            <MotionInView variants={varFadeInRight}>
              <Typography variant="h2" sx={{ mb: 3 }}>
                {title}
              </Typography>
            </MotionInView>

            <MotionInView variants={varFadeInRight}>
              <Typography
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "text.secondary"
                      : "common.white",
                }}
              >
                {description}
              </Typography>
            </MotionInView>

            {/* <Box sx={{ my: 5 }}>
              {MOCK_SKILLS.map((progress) => (
                <MotionInView key={progress.label} variants={varFadeInRight}>
                  <ProgressItem progress={progress} />
                </MotionInView>
              ))}
            </Box> */}

            {button && (
              <MotionInView variants={varFadeInRight}>
                <Button
                  component={Link}
                  href={button.href}
                  variant="outlined"
                  color="inherit"
                  size="large"
                  endIcon={
                    <Icon icon={roundArrowRightAlt} width={24} height={24} />
                  }
                  sx={{ mt: 3 }}
                >
                  {button.label}
                </Button>
              </MotionInView>
            )}
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
