import { Navigate, useRoutes } from "react-router-dom";
// layouts
import MainLayout from "layouts/main";
import Home from "./../screens/home/index";
import Checkout from "./../screens/checkout/index";
import Declaration from "screens/declaration";
import Maintenance from "pages/Maintenance";
import ECpf from "screens/ecpf";
import ECnpj from "screens/ecnpj";
import EPj from "../screens/epj";
import LGPD from "../screens/lgpd";
import Privacy from "../screens/privacy";
import Conclusion from "screens/checkout/components/conclusion";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/ecpf",
          element: <ECpf />,
        },
        {
          path: "/ecnpj",
          element: <ECnpj />,
        },
        {
          path: "/epj",
          element: <EPj />,
        },
        {
          path: "/lgpd",
          element: <LGPD />,
        },   
        {
          path: "/privacy",
          element: <Privacy />,
        },                
        {
          path: "checkout/:step",
          element: <Checkout />,
        },
        {
          path: "checkout/conclusao",
          element: <Conclusion />,
        },
        {
          path: "declaracao",
          element: <Declaration />,
        },
        {
          path: "*",
          element: <Navigate to="/" />,
        },
      ],
    },
  ]);
}
